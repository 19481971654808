<!--
 * @Author: hwu
 * @Date: 2020-11-26 19:01:36
 * @Description: 订单详情页 -> pos机支付 -> 选择支付方式弹窗
 * @LastEditTime: 2024-04-10 16:44:26
-->
<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '70%' }" v-if="show">
        <div class="payment-box">
          <div class="payment-header">
            <span class="header-title">请选择收款方式</span>
          </div>
          <div class="payment-body">
            <div class="payment-way" v-for="(item, index) in payWayList" :key="index" @click="selectPayWay(item.code)">
              <div class="payment-way_logo">
                <svg slot="icon" class="icon" aria-hidden="true">
                  <use :xlink:href="transformPayWayIcon(item.code)" />
                </svg>
              </div>
              <div class="payment-way_name">
                {{item.name}}
                <span v-show="item.code === 0">【首选】</span>
                <p v-show="item.code === 3 || item.code === 4">扫顾客的支付码收款</p>
                <p v-show="item.code === 0">顾客用微信扫本设备显示的二维码完成支付</p>
                <p v-show="item.code === 0">此支付方式可将顾客转化为注册会员</p>
              </div>
              <div class="payment-way_select">
                <svg aria-hidden="true" v-if="item.code === selectedWay">
                  <use xlink:href="#icondanxuan-xuanzhong" />
                </svg>
                <svg aria-hidden="true" v-else>
                  <use xlink:href="#icondanxuan-weixuanzhong" />
                </svg>
              </div>
            </div>
          </div>
          <btn-footer>
            <template slot="body">
              <div class="btn-box">
                <button class="btn-item right-btn" @click="confirmPayWay">
                  <span>应收￥</span>
                  <span class="order-amount">{{payAmount}}</span>
                </button>
              </div>
            </template>
          </btn-footer>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onBackdropClick" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import BtnFooter from '@/components/common/BtnFooter'
import { listStorePayWaysApi } from '@/api/store'
export default {
  name: 'payment-pos-way-popup',
  components: { BtnFooter },
  props: {
    show: { type: Boolean, required: true, default: false },
    payAmount: { type: Number, required: true, default: 0 }
  },
  data () {
    return {
      selectedWay: 0,
      allPayWayList: [
        {
          code: 0,
          name: '微信扫码',
          logo: '#iconweixinsaomazhifu'
        },
        {
          code: 1,
          name: '现金',
          logo: '#iconxianjinzhifu1'
        },
        {
          code: 2,
          name: '刷卡',
          logo: '#iconshuakazhifu'
        },
        {
          code: 3,
          name: '微信',
          logo: '#iconweixinzhifu'
        },
        {
          code: 4,
          name: '支付宝',
          logo: '#iconzhifubaozhifu1'
        }
      ],
      payWayList: [],
      btnText: ''
    }
  },
  computed: {
    ...mapState('order', ['storeId'])
  },
  watch: {
    show (val) {
      if (val) {
        this.listStorePayWays()
      }
    }
  },
  methods: {
    listStorePayWays () {
      listStorePayWaysApi(this.storeId).then(res => {
        if (res.status !== 0 || !res.data || res.data.length === 0) {
          this.$toast('门店支付方式获取失败')
          return false
        }
        const payWayList = res.data
        payWayList.forEach(i => {
          i.code = parseInt(i.code)
        })
        // 如果该门店支持微信支付，则手动添加客扫支付选项
        if (payWayList.findIndex(item => item.code === 3) >= 0) {
          const customScanPay = {
            code: 0,
            name: '微信扫码'
          }
          payWayList.push(customScanPay)
        }
        this.payWayList = this.sortPayWay(payWayList)
      })
    },
    sortPayWay (payWayList) {
      let returnList = []
      if (payWayList.find(item => item.code === 0)) {
        returnList.push(payWayList.find(item => item.code === 0))
      }
      if (payWayList.find(item => item.code === 3)) {
        returnList.push(payWayList.find(item => item.code === 3))
      }
      if (payWayList.find(item => item.code === 4)) {
        returnList.push(payWayList.find(item => item.code === 4))
      }
      if (payWayList.find(item => item.code === 1)) {
        returnList.push(payWayList.find(item => item.code === 1))
      }
      if (payWayList.find(item => item.code === 2)) {
        returnList.push(payWayList.find(item => item.code === 2))
      }
      return returnList
    },
    transformPayWayIcon (code) {
      const payWay = this.allPayWayList.find(x => x.code === code)
      if (!payWay) {
        return ''
      }
      return payWay.logo
    },
    selectPayWay (val) {
      this.selectedWay = parseInt(val)
      this.$emit('selectedWay', this.selectedWay)
    },
    confirmPayWay () {
      if (!this.selectedWay && this.selectedWay !== 0) {
        this.$toast('请选择支付方式')
        return false
      }
      this.$emit('onSubmit', this.selectedWay)
    },
    onBackdropClick () {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.payment-box {
  position: relative;
  width: 100%;
  height: 100%;
  .payment-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 22px 24px ;
    align-items: center;
    flex-wrap: nowrap;
    background-color: $color-white;
    border-radius: 32px 32px 0 0;
    z-index: 11;
    .header-title {
      flex: 1;
      line-height: 36px;
      font-size: 30px;
      font-weight: bold;
      color: $color-text-main;
      white-space: nowrap;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px ;
      right: 24px ;
      border-top: 1px solid $color-border;
    }
  }
  .payment-body {
    width: 100%;
    height: 100%;
    padding: 80px 0 100px 0;
    overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
}

.payment-way {
  position: relative;
  display: flex;
  width: 100%;
  padding: 24px ;
  align-items: flex-start;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24px ;
    right: 24px ;
    border-top: 1px solid $color-border;
  }
  .payment-way_logo {
    flex: 0;
    font-size: 0;
    svg {
      width: 36px;
      height: 36px;
    }
  }
  .payment-way_name {
    flex: 1;
    margin-left: 24px ;
    line-height: 36px;
    font-size: 30px;
    color: $color-text-main;
    span {
      font-size: 24px ;
      color: $color-warning;
    }
    p {
      color: $color-text-sub;
      font-size: 24px ;
      padding: 0;
      margin: 0;
    }
    p:last-child {
      color: $color-warning;
    }
  }
  .payment-way_select {
    flex: 0;
    color: $color-text-disabled;
    &.active {
      color: $color-primary;
    }
    i {
      font-size: 40px;
      vertical-align: bottom;
    }
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.page-footer {
  .btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
  .order-amount {
    font-size: 48px;
    font-weight: bold;
  }
}
</style>
