<!--
 * @Author: hwu
 * @Date: 2021-02-05 21:49:58
 * @Description: 订单支付页面 -> 活动说明弹窗
 * @LastEditTime: 2023-11-15 15:56:19
-->
<template>
  <div class="dialog-wrapper">
    <dialog-box :title="promo.promoName" :show="show" rightBtnText="知道了" @onRightClick="onClose">
      <div slot="body">
        <!-- promoSet小于20的是到店活动 -->
        <div class="explain-row" v-if="promo.promoSet && promo.promoSet < 20">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info" v-if="promo.orderAmount">消费满{{ promo.orderAmount }}元</span>
          <!-- 这里由于后端数据处理，满类的活动，orderItemIds也不为空，所以做一遍过滤 -->
          <template v-if="promo.orderItemIds && promo.orderItemIds.length > 0 && !promo.orderAmount">
            <span class="explain-row_info">购买</span>
            <span class="explain-row_link" @click="showItemPopup(promo.orderItemIds, 1)">{{ promo.orderItemIds.length }}款指定商品</span>
          </template>
          <!-- 4特价、3打折 会有第几份 -->
          <span class="explain-row_info" v-if="[3, 4].indexOf(promo.promoSet) > -1">第{{ promo.originCount }}份</span>
          <span class="explain-row_info" v-if="promo.originDiscountAmount">立减{{ promo.originDiscountAmount }}元</span>
          <span class="explain-row_info" v-if="promo.originDiscountRate">打{{ promo.originDiscountRate }}折</span>
          <span class="explain-row_info" v-if="promo.originPrice">特价{{ promo.originPrice }}元</span>
          <template v-if="promo.freeItemIds && promo.freeItemIds.length > 0">
            <span class="explain-row_info">赠送</span>
            <span class="explain-row_link" @click="showItemPopup(promo.freeItemIds, 2)">指定商品</span>
            <span class="explain-row_info">{{ promo.originCount }}份</span>
          </template>
          <!-- 1满减、7满赠、18满折会显示不计入满额的商品 -->
          <template v-if="[1, 7, 18].indexOf(promo.promoSet) > -1">
            <span class="explain-row_info">（ 已参与其他优惠的商品不计入满额</span>
            <template v-if="promo.excludeItemIds && promo.excludeItemIds.length > 0">
              <span class="explain-row_info">，</span>
              <span class="explain-row_link" @click="showItemPopup(promo.excludeItemIds, 1)">{{ promo.excludeItemIds.length }}款指定商品</span>
              <span class="explain-row_info">不计入满额</span>
            </template>
            <span class="explain-row_info">）</span>
          </template>
        </div>
        <!-- 除了到店活动之外 -->
        <template v-else>
          <!-- 除了到店互动，只有券类会设置满额 -->
          <template v-if="promo.orderAmount">
            <!-- 21物业折扣券、34悠订折扣券， 折扣券是“满xx元可用”， 其他券是“每满xx元可用1张” -->
            <div class="explain-row" v-if="[21, 34].indexOf(promo.promoSet) > -1">
              <span class="explain-row_icon">·</span>
              <span class="explain-row_info">消费满{{ promo.orderAmount }}元可使用此券；</span>
            </div>
            <div class="explain-row" v-else>
              <span class="explain-row_icon">·</span>
              <span class="explain-row_info">消费每满{{ promo.orderAmount }}元可使用1张此券；</span>
            </div>
          </template>
          <div class="explain-row" v-if="promo.excludeItemIds && promo.excludeItemIds.length > 0">
            <span class="explain-row_icon">·</span>
            <span class="explain-row_link" @click="showItemPopup(promo.excludeItemIds, 1)">{{ promo.excludeItemIds.length }}款指定商品</span>
            <!-- 21物业折扣券、34悠订折扣券，promoType=1折扣卡 -->
            <span class="explain-row_info" v-if="[21, 34].indexOf(promo.promoSet) > -1 || promo.promoType === 11">不参与折扣；</span>
            <span class="explain-row_info" v-else>不参与抵扣；</span>
          </div>
          <div class="explain-row" v-if="promo.includeItemIds && promo.includeItemIds.length > 0">
            <span class="explain-row_icon">·</span>
            <span class="explain-row_link" @click="showItemPopup(promo.includeItemIds, 1)">{{ promo.includeItemIds.length }}款指定商品</span>
            <span class="explain-row_info">参与折扣；</span>
          </div>
        </template>
        <div class="explain-row" v-if="promo.maxDiscount">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info">最高可优惠{{ promo.maxDiscount }}元；</span>
        </div>
        <div class="explain-row" v-if="promo.perDayTimes">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info" v-if="promo.promoSet && promo.promoSet < 20">每人每天最多可参与{{ promo.perDayTimes }}次；</span>
          <span class="explain-row_info" v-else>每人每天最多可使用{{ promo.perDayTimes }}张；</span>
        </div>
        <div class="explain-row" v-if="promo.channel">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info">{{ channelDesc(promo.channel) }}；</span>
        </div>
        <div class="explain-row" v-if="promo.promoType === 42">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info">每100分可抵扣1元；</span>
        </div>
        <div class="explain-row" v-if="promo.promoType !== 42">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info"> 不与其他优惠折上折。</span>
        </div>
        <div class="explain-row" v-if="promo.tag === '券'">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info">优惠券使用后不支持退款、兑现、找零；</span>
        </div>
        <div class="explain-row" v-if="promo.promoType === 24">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info">刷卡部分不支持开票，如需开票请至商场服务台；</span>
        </div>
        <div class="explain-row" v-if="promo.promoType === 24">
          <span class="explain-row_icon">·</span>
          <span class="explain-row_info">支付的金额不支持退款、兑现、找零；</span>
        </div>
      </div>
    </dialog-box>
    <!-- 商品弹窗组件 -->
    <item-select-popup :show.sync="itemPopupShow" :brandCode="brandCode" :selectedIds="selectedIds" :itemLevel="itemLevel" :editable="false" height="90%" />
  </div>
</template>
<script>
import DialogBox from '@/components/common/DialogBox'
import ItemSelectPopup from '@/components/business/ItemSelectPopup'
export default {
  name: 'payment-promo-explain-dialog',
  components: { DialogBox, ItemSelectPopup },
  props: {
    show: { type: Boolean, required: true, default: false },
    promo: { type: Object, required: true, default: () => {} },
    brandCode: { type: String, required: true, default: '' }
  },
  data() {
    return {
      itemPopupShow: false,
      // 商品层级, 0-商品层，1-规格层，2-口味层
      itemLevel: 1,
      selectedIds: []
    }
  },
  computed: {
    channelDesc() {
      return (val) => {
        if (!val) {
          return ''
        }
        const result = []
        if (val.indexOf('1') > -1) {
          result.push('堂食')
        }
        if (val.indexOf('2') > -1) {
          result.push('外带')
        }
        if (val.indexOf('4') > -1) {
          result.push('外卖')
        }
        if (result.length === 0) {
          return ''
        } else if (result.length === 1) {
          return '仅' + result[0] + '可使用'
        } else {
          return result.join('、') + '均可使用'
        }
      }
    }
  },
  methods: {
    showItemPopup(itemIdList, itemLevel) {
      this.selectedIds = itemIdList
      this.itemLevel = itemLevel
      this.itemPopupShow = true
    },
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>
<style lang="scss" scoped>
// /deep/ .wh-dialog-wrapper {
//   .wh-dialog-body {
//     padding: 24px  40px;
//   }
// // }
.explain-row {
  width: 100%;
  margin-bottom: 8px;
  line-height: 44px ;
  text-align: left;
  .explain-row_icon {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
    color: $color-text-normal;
  }
  .explain-row_link {
    color: $color-primary;
  }
}
</style>
