<!--
 * @Author: hwu
 * @Date: 2020-12-29 00:55:54
 * @Description:  订单支付页面 -> 产品券商品选择弹窗
 * @LastEditTime: 2024-04-02 18:22:54
-->
<template>
  <div class="dialog-wrapper">
    <dialog-box :title="couponData.couponName" :show="show" :showLeftBtn="true" leftBtnText="取消" rightBtnText="选好了" @onLeftClick="close" @onRightClick="submit">
      <div slot="body">
        <div class="single-item-box" v-for="(item, itemIndex) in itemList" :key="itemIndex">
          <div class="single-item-left" v-if="item.image">
            <img class="item-image" :src="transferItemImage(item.image)" />
          </div>
          <div class="single-item-right">
            <div class="item-name-row">
              <span class="item-name">{{ item.itemName }}</span>
            </div>
            <div class="item-desc-row">
              <span class="item-desc">{{ item.description }}</span>
            </div>
            <div class="item-tag-row">
              <span class="item-tag" v-for="(tag, tagIndex) in item.promoTags" :key="tagIndex">{{ tag.name }}</span>
            </div>
            <div class="item-price-row">
              <span class="item-price">{{ item.price | priceFilter }}</span>
              <div class="item-btn-box">
                <span class="item-btn minus-btn" v-show="item.quantity > 0" @click.stop="minusItemQty(item)">
                  <i class="iconfont iconjianshao"></i>
                </span>
                <span class="item-qty" v-show="item.quantity > 0">{{ item.quantity }}</span>
                <span class="item-btn add-btn" :class="{ disabled: item.disabled }" @click.stop="addItemQty(item)">
                  <i class="iconfont iconzengjia-tianchong"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!-- 商品详情弹窗 -->
    <payment-promo-item-detail-popup :show="itemDetailPopupShow" :itemPrice="selectedItemPrice" :itemId="selectedItemId" :productionModelId="selectedProductionModelId" @onClose="itemDetailPopupShow = false" @onSubmit="submitItemDetail"></payment-promo-item-detail-popup>
  </div>
</template>
<script>
import DialogBox from '@/components/common/DialogBox'
import PaymentPromoItemDetailPopup from './PaymentPromoItemDetailPopup'
import { addCouponItemsApi } from '@/api/order'
import { mapState } from 'vuex'
export default {
  name: 'payment-promo-item-dialog',
  components: { DialogBox, PaymentPromoItemDetailPopup },
  props: {
    show: { type: Boolean, required: true, default: false },
    couponData: { type: Object, required: true, default: () => {} }
  },
  data() {
    return {
      itemDetailPopupShow: false,
      selectedItemId: '',
      selectedProductionModelId: '',
      selectedItemPrice: 0,
      itemList: [],
      submitDisabled: false
    }
  },
  filters: {
    priceFilter(val) {
      if (val === 0) {
        return '免费'
      }
      return '￥' + val
    }
  },
  computed: {
    ...mapState('order', ['userId'])
  },
  watch: {
    show(val) {
      if (val) {
        this.initItemList()
        this.submitDisabled = false
      }
    }
  },
  methods: {
    initItemList() {
      this.itemList = JSON.parse(JSON.stringify(this.couponData.items))
      this.itemList.forEach((x) => {
        const specList = []
        if (x.isMoreSpec === 1 && x.spec) {
          specList.push(x.spec)
        }
        if (x.productionModel) {
          specList.push(x.productionModel)
        }
        if (specList.length > 0) {
          x.itemName += `(${specList.join('、')})`
        }
      })
    },
    transferItemImage(key) {
      return process.env.VUE_APP_QNY_LOAD_URL + key + '?imageView2/0/w/400'
    },
    minusItemQty(item) {
      if (!item.quantity) {
        item.quantity = 0
        item.formList = []
        return false
      }
      item.quantity -= 1
      if (item.fixed === 0) {
        item.formList.splice(item.quantity, 1)
      }
      this.refreshGroupStatus()
      this.itemList = JSON.parse(JSON.stringify(this.itemList))
    },
    addItemQty(item) {
      if (item.disabled) {
        return false
      }
      if (item.fixed === 0) {
        this.selectedItemId = item.itemId
        this.selectedProductionModelId = item.productionModelId
        this.selectedItemPrice = item.salePrice
        this.itemDetailPopupShow = true
        return
      }
      if (!item.quantity) {
        item.quantity = 0
      }
      item.quantity += 1
      this.refreshGroupStatus()
      this.itemList = JSON.parse(JSON.stringify(this.itemList))
    },
    submitItemDetail(itemForm) {
      this.itemList.forEach((item) => {
        if (item.itemId === this.selectedItemId && item.productionModelId === this.selectedProductionModelId) {
          if (!item.quantity) {
            item.quantity = 0
          }
          if (item.quantity === 0) {
            item.formList = []
          }
          item.quantity += 1
          item.formList.push(itemForm)
        }
      })
      this.refreshGroupStatus()
      this.itemList = JSON.parse(JSON.stringify(this.itemList))
      this.itemDetailPopupShow = false
    },
    refreshGroupStatus() {
      let selectedQty = 0
      for (let item of this.itemList) {
        selectedQty += item.quantity || 0
      }
      this.itemList.forEach((item) => {
        if (selectedQty >= this.couponData.totalCount) {
          item.disabled = true
          return true
        }
        if (this.couponData.singleCount > 0 && item.quantity >= this.couponData.singleCount) {
          item.disabled = true
          return true
        }
        item.disabled = false
      })
    },
    close(e) {
      this.$emit('onClose', e)
    },
    submit() {
      let selectedQty = 0
      const selectedItems = []
      this.itemList
        .filter((x) => x.quantity > 0)
        .forEach((item) => {
          // 如果是套餐，则直接拿formList里的结果
          selectedQty += item.quantity
          if (item.fixed === 0) {
            selectedItems.push(...item.formList)
            return true
          }
          // 如果是单品，则拼接
          selectedItems.push({
            itemId: item.itemId,
            productionModelId: item.productionModelId,
            make: item.make,
            taste: item.taste,
            quantity: item.quantity
          })
        })
      if (selectedQty < this.couponData.totalCount) {
        this.$toast('请选择商品')
        return false
      }
      if (selectedQty > this.couponData.totalCount) {
        this.$toast('商品数量超出，请重新选择')
        return false
      }
      if (this.submitDisabled) {
        return false
      }
      this.submitDisabled = true
      addCouponItemsApi(this.couponData.orderId, this.couponData.promoId, this.couponData.couponId, this.userId, this.couponData.userId, selectedItems).then((res) => {
        if (res.status !== 0) {
          this.$toast('添加失败')
          this.submitDisabled = false
          return false
        }
        // 接口成功后返回订单详情数据
        this.$emit('onSubmit', res.data)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .wh-dialog-wrapper {
  .wh-dialog-body {
    padding: 24px 0;
  }
}
.single-item-box {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 24px 24px;
  align-items: center;
  text-align: left;
  .single-item-left {
    flex: 0 0 180px;
    margin-right: 20px;
    img {
      width: 100%;
      border-radius: 4px;
    }
  }
  .single-item-right {
    flex: 1;
    .item-name-row {
      width: 100%;
      margin-bottom: 6px;
      .item-name {
        line-height: 34px;
        color: $color-text-main;
        font-size: 30px;
        font-weight: bold;
      }
    }
    .item-desc-row {
      width: 100%;
      margin-bottom: 4px;
      color: $color-text-sub;
      font-size: 24px;
      transform: scale(0.9);
      transform-origin: center left;
      .item-desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        line-height: 34px;
        overflow: hidden;
      }
    }
    .item-tag-row {
      width: 100%;
      min-height: 44px;
      .item-tag {
        display: inline-block;
        padding: 4px 8px;
        font-size: 24px;
        color: $color-warning;
        border: 1px solid $color-warning;
        border-radius: 4px;
        transform: scale(0.66);
        transform-origin: center left;
      }
    }
    .item-price-row {
      display: flex;
      width: 100%;
      align-items: center;
      .item-price {
        flex: 1;
        color: $color-danger;
        font-size: 30px;
      }
      .item-btn-box {
        flex: 0;
        display: flex;
        align-items: center;
        .item-btn {
          font-size: 0;
          i {
            color: $color-primary;
            font-size: 48px;
          }
          &.disabled {
            opacity: 0.5;
          }
        }
        .item-qty {
          display: inline-block;
          min-width: 60px;
          font-size: 26px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}
</style>
