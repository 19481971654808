<!--
 * @Author: hwu
 * @Date: 2020-09-04 01:55:28
 * @Description: 菜谱页面 -> 商品详情弹窗
 * @LastEditTime: 2023-07-20 23:26:40
-->

<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '90%' }" v-if="show">
        <div class="detail-box" @touchstart="onTouchStart" @touchend="onTouchEnd">
          <div class="detail-header" :class="{ 'full-mode': fullHeaderShow && itemInfo.image }">
            <div class="detail-header_image">
              <img :src="transferItemImage(itemInfo.image)" class="item-image" />
            </div>
            <div class="detail-header_info">
              <div class="item-name-row">{{ itemInfo.itemName }}</div>
              <div class="item-price-row">
                <view class="item-price">
                  <span>{{ itemPrice | priceFilter }}</span>
                  <del class="item-price_origin" v-if="!itemInfo.subItemList || itemInfo.subItemList.length === 0">{{ itemInfo.price | priceFilter }}</del>
                  <span class="item-price_normal" v-else>{{ isFixedUnitPackage ? '原价' : '原价最高' }}{{ itemInfo.originalPrice | priceFilter }}</span>
                </view>
              </div>
            </div>
          </div>
          <div class="detail-selected" :class="{ active: openSelectedCard }" v-if="itemSelectedDescList && itemSelectedDescList.length > 0">
            <div class="detail-selected-box">
              <div class="detail-selected-content">
                <span class="result-title">已选：</span>
                <div class="result-content">
                  <span class="result-item" v-for="(desc, descIndex) in itemSelectedDescList" :key="descIndex">{{ desc }}</span>
                </div>
                <div class="collapse-btn" @click="openSelectedCard = true">
                  <i class="iconfont iconfangxiang-xiangxia"></i>
                </div>
              </div>
            </div>
            <div class="detail-selected-box_fixed">
              <div class="detail-selected-content">
                <span class="result-title">已选：</span>
                <div class="result-content">
                  <span class="result-item" v-for="(desc, descIndex) in itemSelectedDescList" :key="descIndex">{{ desc }}</span>
                </div>
                <span class="collapse-btn" @click="openSelectedCard = false">
                  <i class="iconfont iconfangxiang-xiangshang"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="detail-body" @touchmove.stop>
            <div class="detail-sub" v-if="itemInfo.subItemList && itemInfo.subItemList.length > 0">
              <div class="detail-option" v-for="(group, index) in itemInfo.subItemList" :key="index">
                <div class="detail-option_name">
                  <span>{{ group.categoryName }}</span>
                  <span v-if="group.isFixed !== 1">（{{ group.totalNum }}款任选{{ group.selectNum }}款）</span>
                </div>
                <div class="detail-option_list">
                  <div class="detail-option_item" v-for="(sub, subIndex) in group.items" :key="subIndex">
                    <div class="item-row">
                      <div class="item-row_name">
                        <span>{{ sub.itemName }}</span>
                        <span v-if="sub.productionModel">({{ sub.productionModel }})</span>
                        <img class="item-name_icon" :src="productTagIconUrl(sub.productTag)" v-if="productTagIconUrl(sub.productTag)" />
                      </div>
                      <div class="item-row_price">原价{{ sub.price | priceFilter }}</div>
                      <div class="item-row_qty" v-if="group.isFixed == 1">x{{ sub.quantity }}</div>
                      <div class="item-row_remark" v-else-if="sub.saleStatus !== 1">已售完</div>
                      <div class="item-row_btn" v-else>
                        <span class="item-btn minus-btn" v-show="sub.quantity > 0" @click="minusSubItemQty(sub, group)">
                          <i class="iconfont iconjianshao"></i>
                        </span>
                        <span class="item-qty" v-show="sub.quantity > 0">{{ sub.quantity }}</span>
                        <span class="item-btn add-btn" :class="{ disabled: sub.disabled }" @click="addSubItemQty(sub, group)">
                          <i class="iconfont iconzengjia-xiankuang"></i>
                        </span>
                      </div>
                    </div>
                    <div class="item-option" v-for="(option, optionIndex) in sub.selectedList" :key="optionIndex">
                      <el-select class="item-option-select" v-model="option.taste" size="mini" v-if="sub.tasteList && sub.tasteList.length > 0">
                        <el-option class="item-option-select_item" v-for="item in sub.tasteList" :key="item.code" :label="item.name" :value="item.name"> </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-option" v-if="itemInfo.specList && itemInfo.specList.length > 1">
              <div class="detail-option_name">规格</div>
              <div class="detail-option_table">
                <div class="detail-option_btn" v-for="(spec, index) in itemInfo.specList" :key="index">
                  <span class="item-btn" :class="{ active: spec.selected }" @click="checkSpecItem(spec)">{{ spec.name }}</span>
                </div>
              </div>
            </div>
            <div v-for="(spec, index) in itemInfo.specList" :key="index">
              <div class="detail-option" v-if="spec.selected && spec.productionModels && spec.productionModels.length > 1">
                <div class="detail-option_name">口味</div>
                <div class="detail-option_table">
                  <div class="detail-option_btn" v-for="(model, modelIndex) in spec.productionModels" :key="modelIndex">
                    <span class="item-btn" :class="{ active: model.selected, disabled: model.saleStatus !== 1 }" @click="checkMakeItem(model)">{{ model.productionModel }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-option" v-if="itemInfo.tasteList && itemInfo.tasteList.length > 0">
              <div class="detail-option_name">口感</div>
              <div class="detail-option_table">
                <div class="detail-option_btn" v-for="(taste, index) in itemInfo.tasteList" :key="index">
                  <span class="item-btn" :class="{ active: taste.selected, disabled: taste.saleStatus !== 1 }" @click="checkTasteItem(taste)">{{ taste.name }}</span>
                </div>
              </div>
            </div>
            <div class="detail-option" v-if="itemInfo.chargeList && itemInfo.chargeList.length > 0">
              <div class="detail-option_name">加料</div>
              <div class="detail-option_list">
                <div class="detail-option_item" v-for="(charge, index) in itemInfo.chargeList" :key="index">
                  <div class="item-row">
                    <div class="item-row_name">
                      <span>{{ charge.name }}</span>
                      <span v-if="charge.specName || charge.productionModel"> ({{ charge.specName || '' }}{{ charge.productionModel }})</span>
                    </div>
                    <div class="item-row_price">{{ charge.price | priceFilter }}</div>
                    <div class="item-row_btn">
                      <span class="item-btn minus-btn" v-show="charge.quantity > 0" @click="minusFeedItemQty(charge)">
                        <i class="iconfont iconjianshao"></i>
                      </span>
                      <span class="item-qty" v-show="charge.quantity > 0">{{ charge.quantity }}</span>
                      <span class="item-btn add-btn" @click="addFeedItemQty(charge)">
                        <i class="iconfont iconzengjia-xiankuang"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="detail-footer">
            <div class="btn-box">
              <button class="btn-item left-btn" @click="onClose">取消</button>
            </div>
            <div class="btn-box">
              <button class="btn-item right-btn" @click="onSubmit">加入购物车</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onClose" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui'
import { getCouponItemDetailApi } from '@/api/cart'
import { mapState } from 'vuex'
export default {
  name: 'payment-promo-item-detail-popup',
  components: {
    elSelect: Select,
    elOption: Option
  },
  props: {
    show: { type: Boolean, required: true, default: false },
    itemId: { type: String, required: true, default: '' },
    itemPrice: { type: Number, required: true, default: 0 },
    productionModelId: { type: String, required: false, default: '' }
  },
  data () {
    return {
      itemInfo: {},
      openSelectedCard: false,
      fullHeaderShow: true,
      startX: 0,
      startY: 0,
      itemForm: {},
      submitDisabled: false,
      productTagOptions: [
        {
          label: '微辣',
          icon: require('@/assets/images/item-icon_pepper1.png')
        },
        {
          label: '中辣',
          icon: require('@/assets/images/item-icon_pepper2.png')
        },
        {
          label: '重辣',
          icon: require('@/assets/images/item-icon_pepper3.png')
        },
        {
          label: '冷',
          icon: require('@/assets/images/item-icon_cold.png')
        },
        {
          label: '热',
          icon: require('@/assets/images/item-icon_hot.png')
        }
      ]
    }
  },
  filters: {
    priceFilter (val) {
      if (val === 0) {
        return '免费'
      }
      if (!val) {
        return ''
      }
      return '￥' + val
    }
  },
  computed: {
    ...mapState('order', ['storeId', 'userId', 'cartId']),
    productTagIconUrl () {
      return productTag => {
        if (!productTag) {
          return ''
        }
        const productTagInfo = this.productTagOptions.find(x => x.label === productTag)
        if (!productTagInfo) {
          return ''
        }
        return productTagInfo.icon
      }
    },
    itemFormCopy () {
      const itemForm = {
        itemId: this.itemInfo.itemId,
        quantity: this.itemInfo.quantity
      }
      // 规格
      if (this.itemInfo.specList && this.itemInfo.specList.length > 0 && this.itemInfo.specList.some(x => x.selected)) {
        const selectedSpec = this.itemInfo.specList.find(x => x.selected)
        itemForm.itemId = selectedSpec.code
        itemForm.spec = selectedSpec.name
        // 口味
        if (selectedSpec.productionModels && selectedSpec.productionModels.length > 0 && selectedSpec.productionModels.some(x => x.selected)) {
          itemForm.productionModelId = selectedSpec.productionModels.find(x => x.selected).productionModelId
          itemForm.productionModel = selectedSpec.productionModels.find(x => x.selected).productionModel
        }
      }
      // 口感
      if (this.itemInfo.tasteList && this.itemInfo.tasteList.length > 0 && this.itemInfo.tasteList.some(x => x.selected)) {
        itemForm.taste = this.itemInfo.tasteList.find(x => x.selected).name
      }
      // 加料
      if (this.itemInfo.chargeList && this.itemInfo.chargeList.length > 0) {
        itemForm.charges = this.itemInfo.chargeList
          .filter(x => x.quantity > 0)
          .map(x => {
            return {
              itemId: x.code,
              productionModelId: x.productionModelId,
              name: x.name,
              quantity: x.quantity
            }
          })
      }
      if (!this.itemInfo.subItemList || this.itemInfo.subItemList.length === 0) {
        return itemForm
      }
      // 套餐内单品
      const selectedSubItems = []
      if (this.itemInfo.subItemList && this.itemInfo.subItemList.length > 0) {
        this.itemInfo.subItemList.forEach(group => {
          group.items.forEach(sub => {
            if (!sub.selectedList || sub.selectedList.length === 0) {
              selectedSubItems.push({
                itemId: sub.itemId,
                productionModelId: sub.productionModelId,
                name: sub.itemName,
                quantity: sub.quantity
              })
              return true
            }
            sub.selectedList.forEach(option => {
              selectedSubItems.push({
                itemId: sub.itemId,
                productionModelId: sub.productionModelId,
                name: sub.itemName,
                quantity: 1,
                taste: option.taste
              })
            })
          })
        })
      }
      itemForm.subItems = selectedSubItems.filter(x => x.quantity > 0)
      return itemForm
    },
    itemSelectedDescList () {
      const descList = []
      const mainDescArr = []
      if (this.itemFormCopy.spec) {
        mainDescArr.push(this.itemFormCopy.spec)
      }
      if (this.itemFormCopy.productionModel) {
        mainDescArr.push(this.itemFormCopy.productionModel)
      }
      if (this.itemFormCopy.taste) {
        mainDescArr.push(this.itemFormCopy.taste)
      }
      if (mainDescArr.length > 0) {
        const mainDescStr = mainDescArr.join('/')
        if (this.itemFormCopy.quantity > 1) {
          descList.push(`· ${mainDescStr} x ${this.itemFormCopy.quantity}`)
        } else {
          descList.push(`· ${mainDescStr}`)
        }
      }
      if (this.itemFormCopy.subItems && this.itemFormCopy.subItems.length > 0) {
        this.itemFormCopy.subItems.forEach(i => {
          let subName = i.name
          if (i.taste) {
            subName += `(${i.taste})`
          }
          descList.push(`· ${subName} x ${i.quantity * this.itemFormCopy.quantity}`)
        })
      }
      if (this.itemFormCopy.charges && this.itemFormCopy.charges.length > 0) {
        this.itemFormCopy.charges.forEach(i => {
          descList.push(`· ${i.name} x ${i.quantity * this.itemFormCopy.quantity}`)
        })
      }

      return descList
    },
    isFixedUnitPackage () {
      if (!this.itemInfo.subItemList || this.itemInfo.subItemList.length === 0) {
        return false
      }
      // 全部是固定分类的套餐，是固定套餐
      return this.itemInfo.subItemList.every((x) => x.isFixed === 1)
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.getItemDetail()
        // 提交表单的时候，会将提交按钮禁用，所以重新打开弹窗的时候需要解禁
        this.submitDisabled = false
      }
    }
  },
  methods: {
    getItemDetail () {
      getCouponItemDetailApi(this.cartId, this.userId, this.itemId, this.productionModelId).then(res => {
        this.itemInfo = res.data
        this.itemInfo.quantity = 1
        this.initUnitItemInfo()
      })
    },
    initUnitItemInfo () {
      if (!this.itemInfo.subItemList || this.itemInfo.subItemList.length === 0) {
        return false
      }
      this.itemInfo.subItemList.forEach(category => {
        if (!category.items || category.items.length === 0) {
          return true
        }
        category.items.forEach(sub => {
          if (!sub.tasteList) {
            sub.tasteList = []
          }
          // 过滤已沽清的口感
          sub.tasteList = sub.tasteList.filter(x => x.saleStatus === 1)
          // 如果是固定分类，需要判断是否多口感, 否则直接下一个循环
          if (category.isFixed === 0) {
            return true
          }
          // 如果子商品有多口感，需要插入selectedList, 否则直接下一个循环
          if (sub.tasteList.length === 0) {
            return true
          }
          sub.selectedList = []
          for (let i = 0; i < sub.quantity; i++) {
            const defaultTaste = sub.tasteList.length > 0 ? sub.tasteList[0].name : ''
            sub.selectedList.push({
              taste: defaultTaste
            })
          }
        })
      })
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    transferItemImage (key) {
      if (!key) {
        return require('@/assets/images/item-default.png')
      }
      return process.env.VUE_APP_QNY_LOAD_URL + key + '?imageView2/0/w/400'
    },
    onTouchStart (e) {
      this.startX = e.touches[0].pageX
      this.startY = e.touches[0].pageY
    },
    onTouchEnd (e) {
      // 如果不是大图展示，则不需要监测滑动
      if (!this.fullHeaderShow) {
        return false
      }
      const endX = e.changedTouches[0].pageX
      const endY = e.changedTouches[0].pageY
      const direction = this.calTouchDirection(this.startX, this.startY, endX, endY)
      if (direction === 3) {
        this.fullHeaderShow = false
      }
    },
    calTouchDirection (startX, startY, endX, endY) {
      const distanceX = endX - startX
      const distanceY = endY - startY
      // 如果滑动距离太短, 判定为未滑动
      if (Math.abs(distanceX) < 2 && Math.abs(distanceY) < 2) {
        return 0
      }
      // 计算滑动的角度
      const angle = (Math.atan2(distanceX, distanceY) * 180) / Math.PI
      // 向左滑动
      if (angle >= -135 && angle <= -45) {
        return 1
      }
      // 向右滑动
      if (angle > 45 && angle < 135) {
        return 2
      }
      // 向上滑动
      if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
        return 3
      }
      // 向下滑动
      if (angle >= -45 && angle <= 45) {
        return 4
      }
      return 0
    },
    minusSubItemQty (sub, group) {
      if (!sub.quantity) {
        sub.quantity = 1
      }
      sub.quantity -= 1
      if (sub.tasteList && sub.tasteList.length > 0) {
        sub.selectedList.pop()
      }
      this.refreshGroupStatus()
    },
    addSubItemQty (sub, group) {
      if (sub.disabled) {
        return false
      }
      if (!sub.quantity) {
        sub.quantity = 0
        sub.selectedList = []
      }
      sub.quantity += 1
      let defaultTaste = ''
      if (sub.tasteList && sub.tasteList.length > 0) {
        defaultTaste = sub.tasteList.length > 0 ? sub.tasteList[0].name : ''
      }
      if (defaultTaste) {
        sub.selectedList.push({
          taste: defaultTaste
        })
      }
      this.refreshGroupStatus()
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    refreshGroupStatus () {
      this.itemInfo.subItemList.forEach(group => {
        let selectedQty = 0
        for (let sub of group.items) {
          selectedQty += sub.quantity || 0
        }
        group.items.forEach(sub => {
          if (selectedQty >= group.selectNum) {
            sub.disabled = true
            return true
          }
          if (group.maxCount > 0 && sub.quantity >= group.maxCount) {
            sub.disabled = true
            return true
          }
          sub.disabled = false
        })
      })
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    minusFeedItemQty (feed) {
      if (!feed.quantity) {
        feed.quantity = 1
      }
      feed.quantity -= 1
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    addFeedItemQty (feed) {
      if (!feed.quantity) {
        feed.quantity = 0
      }
      feed.quantity += 1
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    checkSpecItem (val) {
      this.itemInfo.specList.forEach(x => {
        x.selected = false
      })
      val.selected = true
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    checkMakeItem (val) {
      if (val.saleStatus !== 1) {
        return false
      }
      this.itemInfo.specList.forEach(spec => {
        spec.productionModels.forEach(x => {
          x.selected = false
        })
      })
      val.selected = true
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    checkTasteItem (val) {
      if (val.saleStatus !== 1) {
        return false
      }
      this.itemInfo.tasteList.forEach(x => {
        x.selected = false
      })
      val.selected = true
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    checkFeedItem (val) {
      val.selected = !val.selected
      this.itemInfo = JSON.parse(JSON.stringify(this.itemInfo))
    },
    onClose (e) {
      this.fullHeaderShow = true
      this.$emit('onClose', e)
    },
    validForm () {
      this.itemForm = {
        itemId: this.itemInfo.itemId,
        productionModelId: this.productionModelId,
        quantity: this.itemInfo.quantity
      }
      // 规格
      if (this.itemInfo.specList && this.itemInfo.specList.length > 0) {
        if (this.itemInfo.specList.length > 1 && !this.itemInfo.specList.some(x => x.selected)) {
          return '请选择规格'
        }
        const selectedSpec = this.itemInfo.specList.find(x => x.selected)
        this.itemForm.itemId = selectedSpec.code
        // 口味
        if (selectedSpec.productionModels && selectedSpec.productionModels.length === 1) {
          this.itemForm.productionModelId = selectedSpec.productionModels[0].productionModelId
        }
        if (selectedSpec.productionModels && selectedSpec.productionModels.length > 1) {
          if (!selectedSpec.productionModels.some(x => x.selected)) {
            return '请选择口味'
          }
          this.itemForm.productionModelId = selectedSpec.productionModels.find(x => x.selected).productionModelId
        }
      }
      // 口感
      if (this.itemInfo.tasteList && this.itemInfo.tasteList.length > 0) {
        if (!this.itemInfo.tasteList.some(x => x.selected)) {
          return '请选择口感'
        }
        this.itemForm.taste = this.itemInfo.tasteList.find(x => x.selected).name
      }
      // 加料
      if (this.itemInfo.chargeList && this.itemInfo.chargeList.length > 0) {
        this.itemForm.charges = this.itemInfo.chargeList
          .filter(x => x.quantity > 0)
          .map(x => {
            return {
              itemId: x.code,
              productionModelId: x.productionModelId,
              name: x.name,
              quantity: x.quantity
            }
          })
      }
      if (!this.itemInfo.subItemList || this.itemInfo.subItemList.length === 0) {
        return ''
      }
      // 套餐内单品
      let disabled = false
      const selectedSubItems = []
      if (this.itemInfo.subItemList && this.itemInfo.subItemList.length > 0) {
        this.itemInfo.subItemList.forEach(group => {
          let groupSelected = 0
          group.items.forEach(sub => {
            groupSelected += sub.quantity || 0
            if (!sub.selectedList || sub.selectedList.length === 0) {
              selectedSubItems.push({
                itemId: sub.itemId,
                productionModelId: sub.productionModelId,
                name: sub.name,
                quantity: sub.quantity
              })
              return true
            }
            sub.selectedList.forEach(option => {
              selectedSubItems.push({
                itemId: sub.itemId,
                productionModelId: sub.productionModelId,
                name: sub.name,
                quantity: 1,
                taste: option.taste
              })
            })
          })
          // 如果不是固定分类，并且选的分数不满足要求，则不能提交
          if (group.isFixed !== 1 && groupSelected < group.selectNum) {
            disabled = true
            return false
          }
        })
      }
      if (disabled) {
        return '请选择菜品'
      }
      this.itemForm.subItems = selectedSubItems.filter(x => x.quantity > 0)
      return ''
    },
    onSubmit (e) {
      const errMsg = this.validForm()
      if (errMsg) {
        this.$toast(errMsg)
        return false
      }

      if (this.submitDisabled) {
        return false
      }
      this.submitDisabled = true

      this.$emit('onSubmit', this.itemForm)
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.detail-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  .detail-header {
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    padding: 24px ;
    flex-wrap: nowrap;
    align-items: flex-start;
    &.full-mode {
      padding: 0;
      margin-bottom: 24px ;
      flex-wrap: wrap;
      .detail-header_image {
        flex: 0 0 100%;
        margin-bottom: 24px ;
        .item-image {
          width: 100vw;
        }
      }
      .detail-header_info {
        padding: 0 24px ;
      }
    }
    .detail-header_image {
      flex: 0 0 216px;
      margin-right: 24px ;
      .item-image {
        width: 216px;
        transition: width linear 0.5s;
      }
    }
    .detail-header_info {
      flex: 1;
      .item-name-row {
        margin-bottom: 24px ;
        line-height: 32px;
        font-size: 30px;
        font-weight: bold;
        color: $color-text-main;
      }
      .item-rank-row {
        margin-bottom: 36px;
        font-size: 24px ;
        color: $color-text-sub;
        .item-rank-item:not(:last-child) {
          margin-right: 20px;
        }
      }
      .item-price-row {
        display: flex;
        width: 100%;
        align-items: center;
        .item-price {
          flex: 1;
          color: $color-danger;
          font-size: 30px;
          .item-price_normal {
            font-size: 24px ;
            color: $color-text-normal;
          }
          .item-price_desc {
            font-size: 24px ;
            color: $color-text-sub;
          }
          .item-price_origin {
            margin-left: 16px;
            font-size: 24px ;
            color: $color-text-sub;
            text-decoration: line-through;
          }
        }
        .item-btn-box {
          flex: 0;
          display: flex;
          align-items: center;
          .item-btn {
            font-size: 0;
            i {
              color: $color-primary;
              font-size: 48px;
            }
            &.disabled {
              opacity: 0.5;
            }
          }
          .item-qty {
            display: inline-block;
            min-width: 60px;
            font-size: 26px;
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }
  }
  .detail-selected {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    padding: 24px ;
    font-size: 24px ;
    &.active {
      .detail-selected-box {
        visibility: hidden;
      }
      .detail-selected-box_fixed {
        display: block;
        max-height: 500px;
        opacity: 1;
      }
    }
    .detail-selected-box {
      position: relative;
      width: 100%;
      .detail-selected-content {
        position: relative;
        display: flex;
        width: 100%;
        line-height: 30px;
        padding: 16px 80px 16px 24px ;
        background-color: #f3f6fa;
        .result-content {
          height: 30px;
          line-height: 30px;
          overflow: hidden;
        }
      }
      .collapse-btn {
        position: absolute;
        top: 16px;
        right: 30px;
        z-index: 11;
      }
    }
    .detail-selected-box_fixed {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      max-height: 0;
      padding: 24px ;
      overflow: hidden;
      z-index: 10;
      opacity: 0;
      transition: all 0.5s;
      z-index: 20;
      .detail-selected-content {
        position: relative;
        display: flex;
        width: 100%;
        padding: 16px 24px  48px 24px ;
        line-height: 36px;
        background-color: #f3f6fa;
        .collapse-btn {
          position: absolute;
          bottom: 10px;
          right: 30px;
          z-index: 21;
        }
      }
    }
    .result-title {
      flex: 0;
      white-space: nowrap;
    }
    .result-content {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      .result-item {
        flex: 0 0 auto;
        padding: 0 12px;
        margin-bottom: 8px;
        white-space: nowrap;
      }
    }
  }
  .detail-body {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    .detail-option {
      width: 100%;
      margin-bottom: 40px;
      .detail-option_name {
        position: relative;
        width: 100%;
        padding: 0 24px  0 36px;
        margin-bottom: 24px ;
        line-height: 32px;
        font-size: 26px;
        font-weight: bold;
        color: $color-warning;
        &::before {
          position: absolute;
          left: 24px ;
          top: 50%;
          margin-top: -12px;
          content: '';
          width: 4px ;
          height: 24px ;
          background: $color-warning;
          border-radius: 2px;
        }
      }
      .detail-option_list {
        width: 100%;
        padding: 6px 24px  0;
        .detail-option_item {
          width: 100%;
          margin-bottom: 30px;
          .item-row {
            display: flex;
            width: 100%;
            flex-wrap: nowrap;
            .item-row_name {
              flex: 1;
              .item-name_icon {
                width: auto;
                height: 36px;
                margin-left: 10px;
                vertical-align: sub;
              }
            }
            .item-row_price {
              flex: 0;
              padding: 0 60px;
              white-space: nowrap;
            }
            .item-row_qty {
              flex: 0;
              min-width: 122px;
              text-align: right;
            }
            .item-row_remark {
              flex: 0;
              min-width: 122px;
              text-align: right;
              color: $color-text-sub;
              font-size: 26px;
            }
            .item-row_btn {
              flex: 0;
              display: flex;
              min-width: 122px;
              align-items: center;
              justify-content: flex-end;
              .item-btn {
                font-size: 0;
                i {
                  color: $color-primary;
                  font-size: 40px;
                }
                &.disabled {
                  opacity: 0.5;
                }
              }
              .item-qty {
                display: inline-block;
                min-width: 40px;
                font-size: 26px;
                font-weight: bold;
                text-align: center;
              }
            }
          }
          .item-option {
            width: 100%;
            margin-top: 16px;
            .item-option-select:not(:last-child) {
              margin-right: 32px;
            }
          }
        }
      }
      .detail-option_table {
        // display: flex;
        width: 100%;
        padding: 0 12px;
        .detail-option_btn {
          // flex: 0;
          display: inline-block;
          padding: 0 12px;
          margin-bottom: 12px;
          .item-btn {
            display: inline-block;
            min-width: 120px;
            padding: 10px;
            text-align: center;
            color: $color-text-sub;
            font-size: 24px ;
            border: 1px solid $color-text-sub;
            border-radius: 8px;
            &.active {
              color: $color-primary;
              font-weight: bold;
              background-color: rgba($color-primary, 0.3);
              border: 1px solid $color-primary;
            }
            &.disabled {
              color: $color-text-disabled;
              font-weight: bold;
              background-color: rgba($color-text-disabled, 0.1);
              border: 1px solid $color-text-disabled;
            }
          }
        }
      }
    }
  }
  .detail-footer {
    flex: 0 0 98px;
    display: flex;
    width: 100%;
    padding: 0 24px ;
    align-items: center;
    background-color: $color-white;
    border-top: 1px solid $color-border;
    box-sizing: border-box;
    z-index: 99;
    .btn-box {
      flex: 1 1 50%;
      padding: 0 9px;
      font-size: 30px;
      .btn-item {
        width: 100%;
        height: 80px;
        line-height: 72px;
        border: 4px  solid $color-primary;
        border-radius: 8px;
        &:focus {
          outline: 0;
        }
        &.left-btn {
          color: $color-primary;
          background-color: $color-white;
        }
        &.right-btn {
          color: $color-white;
          background-color: $color-primary;
        }
      }
    }
  }
}

/deep/ .el-select {
  width: 200px;
  .el-input {
    .el-input__inner {
      height: 52px !important;
      line-height: 52px !important;
      padding: 0 40px 0 10px;
      font-size: 24px ;
      color: $color-primary;
      border-color: $color-primary;
      &:focus {
        border-color: $color-primary;
      }
    }
    &.is-focus .el-input__inner {
      border-color: $color-primary;
    }
    .el-input__suffix {
      right: 0;
      .el-input__icon {
        font-size: 26px;
        line-height: 48px !important;
        color: $color-primary;
      }
    }
  }
}
.item-option-select_item {
  font-size: 24px ;
  padding: 0 20px;
  // height: 60px;
  // line-height: 60px;
  &.selected {
    color: $color-primary;
  }
}
</style>
