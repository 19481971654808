<!--
 * @Author: hwu
 * @Date: 2021-03-18 17:25:17
 * @Description: 订单支付页 -> 券包里不可用的优惠券
 * @LastEditTime: 2021-07-28 00:02:05
-->
<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '80%' }" v-if="show">
        <div class="popup-box">
          <div class="popup-header">
            <span class="header-title">其他不满足使用条件的优惠券 ({{couponNum}})</span>
          </div>
          <div class="popup-body">
            <div class="coupon-list">
              <div class="single-coupon-box" v-for="(coupon, index) in coupons" :key="index">
                <div class="coupon-content">
                  <div class="coupon-content-left">
                    <div class="coupon-amount">
                      <span class="coupon-amount_icon">￥</span>
                      <span class="coupon-amount_value">{{coupon.amount}}</span>
                    </div>
                    <div class="coupon-limit" v-if="coupon.orderAmount">满{{coupon.orderAmount}}元可用</div>
                  </div>
                  <div class="coupon-content-right">
                    <div class="coupon-name">
                      <span>{{coupon.promoName}}</span>
                      <span class="coupon-name_count">x{{coupon.totalNum}}</span>
                    </div>
                    <div class="coupon-date">期限：{{coupon.beginDate || '2021-03-01'}} - {{coupon.endDate || '2021-04-31'}}</div>
                    <div class="coupon-operate">
                      <div class="coupon-operate_collapse">
                        <span>使用规则</span>
                        <i class="iconfont iconfangxiang-xiangxia"></i>
                      </div>
                      <div class="coupon-operate_rule">
                        {{coupon.reason}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <btn-footer rightBtnText="返回" @onRightClick="onClose"></btn-footer>
          </div>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-popup-backdrop" @click="onClose" v-if="show"></div>
    </transition>
  </div>
</template>
<script>
import BtnFooter from '@/components/common/BtnFooter'
export default {
  name: 'payment-promo-coupon-popup',
  components: { BtnFooter },
  props: {
    show: { type: Boolean, required: true, default: false },
    coupons: { type: Array, require: true, default: () => [] }
  },
  computed: {
    couponNum () {
      if (this.coupons.length === 0) {
        return 0
      }
      let num = 0
      this.coupons.forEach(item => {
        if (!item.totalNum) {
          num += 1
          return true
        }
        num += item.totalNum
      })
      return num
    }
  },
  methods: {
    onClose (e) {
      this.$emit('onClose', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

.popup-box {
  position: relative;
  width: 100%;
  height: 100%;
  .popup-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 22px 24px ;
    align-items: center;
    flex-wrap: nowrap;
    background-color: $color-white;
    border-radius: 32px 32px 0 0;
    z-index: 2002;
    .header-title {
      flex: 1;
      line-height: 36px;
      font-size: 30px;
      font-weight: bold;
      color: $color-text-main;
      white-space: nowrap;
    }
    .header-btn {
      flex: 0;
      line-height: 40px;
      font-size: 24px ;
      color: $color-text-sub;
      white-space: nowrap;
      i {
        margin-left: 10px;
        color: $color-text-disabled;
        font-size: 40px;
        vertical-align: bottom;
        &.active {
          color: $color-primary;
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px ;
      right: 24px ;
      border-top: 1px solid $color-border;
    }
  }
  .popup-body {
    width: 100%;
    height: 100%;
    padding: 80px 0 100px 0;
    background-color: $color-background;
    overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
}
.coupon-list {
  width: 100%;
  padding: 16px 24px ;
}
.single-coupon-box {
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  opacity: 0.6;
  .coupon-content {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 176px;
    align-items: center;
    background-image: url('../../assets/images/coupon-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    .coupon-content-left {
      flex: 0 0 180px;
      padding: 0 14px ;
      color: $color-white;
      text-align: center;
      .coupon-amount {
        .coupon-amount_icon {
          font-size: 24px ;
        }
        .coupon-amount_value {
          margin-left: 6px;
          font-size: 52px;
        }
      }
      .coupon-limit {
        margin-top: 10px;
        font-size: 24px ;
        transform: scale(0.9);
        transform-origin: center;
      }
    }
    .coupon-content-right {
      flex: 1;
      padding: 16px 24px  16px 36px;
      overflow: hidden;
      .coupon-name {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        padding-right: 80px;
        font-size: 30px;
        font-weight: bold;
        color: $color-text-main;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .coupon-name_count {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 30px;
          font-weight: bold;
          color: $color-text-main;
        }
      }
      .coupon-date {
        margin-bottom: 24px ;
        font-size: 24px ;
        color: $color-text-sub;
        transform: scale(0.9);
        transform-origin: center left;
      }
      .coupon-operate {
        display: flex;
        width: 100%;
        min-height: 32px;
        align-items: center;
        .coupon-operate_collapse {
          flex: 0;
          font-size: 24px ;
          color: $color-primary;
          white-space: nowrap;
          .iconfont {
            margin-left: 10px;
            font-size: 24px ;
          }
        }
        .coupon-operate_rule {
          flex: 1;
          text-align: right;
          font-size: 24px ;
          color: $color-danger;
        }
        .coupon-operate_desc {
          flex: 1;
          text-align: right;
          font-size: 24px ;
          color: $color-text-normal;
        }
        .coupon-operate_btn {
          flex: 1;
          text-align: right;
          .btn-item {
            display: inline-block;
            width: auto;
            height: 44px ;
            line-height: 40px;
            padding: 0 20px;
            text-align: center;
            background-color: $color-primary;
            border-radius: 22px;
            .btn-item_text {
              display: inline-block;
              font-size: 24px ;
              font-weight: bold;
              color: $color-white;
              transform: scale(0.9);
              transform-origin: center;
            }
          }
        }
      }
    }
  }
  .coupon-detail {
    width: 100%;
    max-height: 0;
    padding: 0;
    background-color: $color-white;
    overflow: hidden;
    transition: all 0.5s;
    &.active {
      max-height: 500px;
    }
    .coupon-detail-content {
      width: 100%;
      padding: 16px 0;
      .coupon-detail-row {
        position: relative;
        width: 100%;
        padding: 0 24px  0 32px;
        font-size: 24px ;
        color: $color-text-sub;
        transform: scale(0.9);
        transform-origin: center left;
        &::before {
          position: absolute;
          content: '';
          left: 16px;
          top: 14px ;
          width: 8px;
          height: 8px;
          background-color: $color-text-sub;
          border-radius: 4px;
          transform: scale(0.7);
          transform-origin: center;
        }
      }
    }
  }
}
</style>
