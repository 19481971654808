<!--
 * @Author: hwu
 * @Date: 2020-11-26 17:00:13
 * @Description: 选择门店员工输入密码弹窗
 * @LastEditTime: 2024-04-11 19:02:04
-->
<template>
  <dialog-box :title="isBApp && authCallData.status === 0 ? '请输入密码' : title" :show="show" :showLeftBtn="true" leftBtnText="返回" rightBtnText="确定" @onLeftClick="onLeftClick" @onRightClick="onRightClick" v-if="!isBApp || (isBApp && authCallData.status === 0)">
    <div slot="body">
      <div class="emp-box emp-center" v-if="isBApp && authCallData.status === 0">
        <div class="emp-item">
          <div class="emp-photo">
            <img :src="transferPhotoUrl(authCallData.headImg)" />
          </div>
          <div class="emp-name">
            <span>{{ authCallData.name }}</span>
          </div>
        </div>
      </div>
      <div class="emp-box" v-else>
        <div class="emp-item" :class="{ active: selectedUserId === emp.userId }" @click="selectEmp(emp)" v-for="(emp, index) in empList" :key="index">
          <div class="emp-photo">
            <img :src="transferPhotoUrl(emp.photo)" />
          </div>
          <div class="emp-name">
            <span>{{ emp.name }}</span>
          </div>
        </div>
      </div>
      <div class="password-box">
        <form action="" @submit.prevent="$closeKeyboard()">
          <input type="password" class="password-input" v-model="password" placeholder="请输入密码" />
        </form>
      </div>
    </div>
  </dialog-box>
</template>
<script>
import { mapState } from 'vuex'
import DialogBox from '@/components/common/DialogBox'
import { listStoreWaitersApi, loginWaiterApi } from '@/api/store'
export default {
  name: 'emp-login-dialog',
  components: { DialogBox },
  props: {
    show: { type: Boolean, required: true, default: false },
    title: { type: String, required: true, default: '请选择当前操作人' }
  },
  data() {
    return {
      empList: [],
      selectedUserId: '',
      logonName: '',
      password: '',
      authCallData: {} // 生物识别回调数据
    }
  },
  computed: {
    ...mapState('order', ['storeId', 'isPad']),
    isBApp() {
      // app使用指纹识别，把pad剔除
      return this.$bappSdk.isBApp() && !this.isPad
    }
  },
  created() {
    if (!this.isBApp) {
      this.listStoreWaiters()
    }
  },
  watch: {
    show(val) {
      // 是app但又不是pad， 就使用指纹识别
      if (this.isBApp && val) {
        this.$bappSdk.authID((data) => {
          this.authCallData = JSON.parse(data)
          this.logonName = this.authCallData.loginName
          this.selectedUserId = this.authCallData.userId
          // 识别成功
          if (this.authCallData.status === 1) {
            this.$emit('onSuccess', this.selectedUserId)
          } else if (this.authCallData.status === 2) {
            // 识别失败 或 取消
            this.onLeftClick()
          }
        })
      }
    }
  },
  methods: {
    listStoreWaiters() {
      listStoreWaitersApi(this.storeId).then((res) => {
        if (res.status !== 0) {
          this.$toast(res.msg)
          return false
        }
        this.empList = res.data
      })
    },
    transferPhotoUrl(key) {
      if (!key) {
        return require('@/assets/images/icon_head_error.png')
      }
      return process.env.VUE_APP_QNY_LOAD_URL + key + '?imageView2/0/w/200'
    },
    selectEmp(emp) {
      this.selectedUserId = emp.userId
      this.logonName = emp.logonName
    },
    onLeftClick(e) {
      this.logonName = ''
      this.password = ''
      this.selectedUserId = ''
      this.$emit('onClose', e)
    },
    onRightClick(e) {
      if (!this.logonName) {
        this.$toast('请选择当前操作人')
        return false
      }
      if (!this.password) {
        this.$toast('请输入密码')
        return false
      }
      const loginForm = {
        logonName: this.logonName,
        password: this.password
      }
      loginWaiterApi(loginForm).then((res) => {
        if (res.status !== 0) {
          this.$toast('密码错误')
          return false
        }
        this.$emit('onSuccess', this.selectedUserId)
        this.logonName = ''
        this.password = ''
        this.selectedUserId = ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.emp-box {
  display: flex;
  width: 100%;
  padding-bottom: 120px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  &.emp-center {
    justify-content: center;
  }
  .emp-item {
    position: relative;
    flex: 0 0 33%;
    padding: 10px 30px;
    margin-bottom: 16px;
    text-align: center;
    font-size: 28px;
    color: $color-text-main;
    border: 1px solid $color-white;
    border-radius: 10px;
    &.active {
      // color: $color-primary;
      border: 1px solid $color-border;
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        height: 0;
        width: 0;
        font-size: 0;
        border: 24px solid transparent;
        border-right: 24px solid $color-warning;
        border-top: 24px solid $color-warning;
        border-radius: 0 8px 0 0;
      }
      &::after {
        content: '';
        position: absolute;
        top: 6px;
        right: 6px;
        width: 12px;
        height: 6px;
        border: 4px solid $color-white;
        border-radius: 1px;
        border-top: 0px;
        border-right: 0px;
        background: transparent;
        transform: rotate(-45deg);
      }
    }
    .emp-photo {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .emp-name {
      margin-top: 8px;
    }
  }
}
.password-box {
  position: absolute;
  bottom: 88px;
  left: 0;
  width: 100%;
  padding: 24px 40px;
  background-color: $color-white;
  .password-input {
    width: 100%;
    height: 80px;
    padding: 6px 10px;
    border: 1px solid $color-border;
    border-radius: 8px;
    &:focus {
      border: 1px solid $color-primary;
      caret-color: $color-primary;
    }
  }
}
</style>
