<!--
 * @Author: hwu
 * @Date: 2021-02-06 20:58:30
 * @Description: 客户扫码支付选择就餐类型弹窗
 * @LastEditTime: 2021-02-08 19:37:54
-->
<template>
  <div class="dialog-wrapper">
    <dialog-box :title="'请选择就餐类型'" :show="show" leftBtnText="外带" rightBtnText="堂食" :showLeftBtn="true" @onLeftClick="onClose(2)"  @onRightClick="onClose(1)"></dialog-box>
  </div>
</template>
<script>
import DialogBox from '@/components/common/DialogBox'
export default {
  name: 'payment-custom-scan-pay-dialog',
  components: { DialogBox },
  props: {
    show: { type: Boolean, required: true, default: false }
  },
  methods: {
    onClose (e) {
      this.$emit('onClose', e)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .wh-dialog-header {
  padding: 48px 0 48px 0 !important;
}

/deep/ .wh-dialog-body {
  padding: 0 !important;
  min-height: 0 !important;
}
</style>
