<!--
 * @Author: hwu
 * @Date: 2020-10-13 10:29:35
 * @Description: 订单支付页面 -> 物业活动弹窗
 * @LastEditTime: 2024-04-01 16:15:52
-->

<template>
  <div class="dialog-wrapper">
    <!-- 提示弹窗 -->
    <dialog-box title="请叫服务员来，为您验证卡券" :show="show && tipDailogShow" rightBtnText="下一步" @onRightClick="showLoginDialog">
      <div slot="body">
        <img class="dialog-img" src="@/assets/images/empty-6.png" />
      </div>
    </dialog-box>
    <!-- 服务员登录弹窗 -->
    <emp-login-dialog :show="show && loginDialogShow" title="请叫服务员来，为您验证卡券" @onClose="closeDialog" @onSuccess="empLoginSuccess"></emp-login-dialog>
    <!-- 选择物业活动弹窗 -->
    <dialog-box title="本店线下活动列表" class="form-dialog" :show="show && promoDialogShow" :showLeftBtn="true" leftBtnText="关闭" rightBtnText="确定" @onLeftClick="closeDialog" @onRightClick="submitPromo">
      <div slot="body">
        <div class="promo-warning">请完成“第三方后台”核销后或回收纸质卡券后，再在本处核销，违规操作的由操作人承担责任！</div>
        <div class="promo-box" v-for="(promo, index) in promoList" :key="index">
          <!-- <van-cell :title="promo.name" is-link :arrow-direction="arrowDirection(promo.show)" @click="togglePromoDetail(promo)" /> -->
          <van-cell :title="promo.promoName" @click="togglePromoDetail(promo)">
            <template #right-icon>
              <i v-if="isAfterPayPage && promo.promoSet === 24" class="promo-select iconfont iconduoxuan-bukexuan"></i>
              <i v-else class="promo-select iconfont" :class="[promo.selected ? 'iconduoxuan-xuanzhong active' : 'iconduoxuan-weixuanzhong']"></i>
              <!-- iconduoxuan-bukexuan -->
            </template>
          </van-cell>
          <div class="collapse-box" v-if="isAfterPayPage && promo.promoSet === 24" @click="disabledTipShow = true">
            <div class="promo-form white-bg">* 请点击【继续点餐】-【选好了】再勾选本券</div>
          </div>
          <div class="collapse-box" v-else :class="{ hide: !promo.selected }">
            <div class="promo-form">
              <van-cell-group :border="false">
                <van-field type="text" v-model="promo.customerName" label="姓名*" placeholder="持卡人姓名" clearable v-if="promo.needCustomerInfo" />
                <van-field type="digit" v-model="promo.customerMobile" label="手机号*" placeholder="持卡人手机号" clearable v-if="promo.needCustomerInfo" />
                <van-field type="digit" v-model="promo.count" label="使用张数*" placeholder="数量" clearable v-if="promo.promoSet === 22 || promo.promoSet === 24" />
                <!-- 物业储值卡 -->
                <template v-if="promo.promoSet === 23">
                  <van-field type="number" step="0.01" v-model="promo.amount" label="刷卡金额*" @input="amountInput($event, promo, index, 'amount')" placeholder="刷卡金额" clearable />
                  <div class="cell-desc" v-if="promo.excludeItemIds && promo.excludeItemIds.length">{{ promo.excludeItemIds.length }}款指定商品不参与抵扣，本单最高可抵扣{{ promo.maxAmount }}元</div>
                </template>
              </van-cell-group>
            </div>
          </div>
        </div>
      </div>
    </dialog-box>
    <!-- 提示弹窗 -->
    <dialog-box title="温馨提示" :show="disabledTipShow" rightBtnText="知道了" @onRightClick="disabledTipShow = false" class="zIndex">
      <div slot="body">请点击【继续点餐】-【选好了】再验证本券</div>
    </dialog-box>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { CellGroup, Cell, Field } from 'vant'
import DialogBox from '@/components/common/DialogBox'
import EmpLoginDialog from '@/components/business/EmpLoginDialog'
import { listPropertyPromoApi, addPropertyPromosApi } from '@/api/order'
import validate from '@/utils/validate'
export default {
  name: 'payment-promo-property-dialog',
  components: { VanCellGroup: CellGroup, VanCell: Cell, VanField: Field, DialogBox, EmpLoginDialog },
  props: {
    show: { type: Boolean, required: true, default: false },
    orderId: { type: String, required: true, default: '' },
    isAfterPayPage: { type: Boolean, required: false, default: false } // 是否是后买单界面
  },
  data() {
    return {
      tipDailogShow: true,
      loginDialogShow: false,
      empUserId: '',
      promoDialogShow: false,
      promoList: [],
      disabledTipShow: false
    }
  },
  computed: {
    ...mapState('order', ['storeId', 'userId', 'existsWeighing']),
    arrowDirection() {
      return (val) => {
        if (val) {
          return 'down'
        }
        return ''
      }
    },
    checkboxIcon() {
      return (val) => {
        if (val) {
          return 'iconduoxuan-xuanzhong'
        }
        return 'iconduoxuan-weixuanzhong'
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.closeAllDialog()
        this.listStorePromos()
      }
    }
  },
  methods: {
    listStorePromos() {
      listPropertyPromoApi(this.storeId, this.orderId, this.userId).then((res) => {
        if (res.status !== 0) {
          this.$toast('门店未打通活动获取失败')
          this.closeDialog()
          return false
        }
        if (!res.data || res.data.length === 0) {
          this.$toast('本店没有可参与的未打通活动')
          this.closeDialog()
          return false
        }
        this.promoList = res.data
        this.initDialog()
      })
    },
    initDialog() {
      // 如果是pos机点餐，并且有称重商品，则无需弹出登录窗口
      if (this.existsWeighing && this.userId) {
        this.empLoginSuccess(this.userId)
      } else {
        this.closeAllDialog()
        this.tipDailogShow = true
        this.empUserId = ''
      }
    },
    closeAllDialog() {
      this.tipDailogShow = false
      this.loginDialogShow = false
      this.promoDialogShow = false
    },
    showLoginDialog() {
      this.closeAllDialog()
      this.loginDialogShow = true
    },
    empLoginSuccess(val) {
      this.empUserId = val
      this.closeAllDialog()
      this.promoDialogShow = true
    },
    togglePromoDetail(promo) {
      if (this.isAfterPayPage && promo.promoSet === 24) {
        this.disabledTipShow = true
        return false
      }
      promo.selected = !promo.selected
      this.promoList = JSON.parse(JSON.stringify(this.promoList))
    },
    validForm() {
      const selectedPromos = this.promoList.filter((x) => x.selected)
      // if (selectedPromos.length === 0) {
      //   return '请选择活动'
      // }
      let errMsg = ''
      selectedPromos.forEach((promo) => {
        // 如果需要手机客户信息，需要填写姓名手机号
        if (promo.needCustomerInfo) {
          if (!promo.customerName) {
            errMsg = '请填写持卡人姓名'
            return false
          }
          if (!promo.customerMobile) {
            errMsg = '请填写持卡人手机号'
            return false
          }
          if (!validate.mobile(promo.customerMobile)) {
            errMsg = '请填写正确的手机号'
            return false
          }
        }
        // 物业现金券，需要填写张数
        if (promo.promoSet === 22 || promo.promoSet === 24) {
          if (!validate.positiveInteger(promo.count)) {
            errMsg = '请填写券使用张数'
            return false
          }
          if (promo.maxCount && parseInt(promo.count) > parseInt(promo.maxCount)) {
            errMsg = `${promo.promoName}最多可使用${promo.maxCount}张`
            return false
          }
        }
        // 储值卡，需要填写刷卡金额
        if (promo.promoSet === 23) {
          if (!validate.amount(promo.amount)) {
            errMsg = '请填写刷卡金额'
            return false
          }
          if (promo.maxAmount && Number(promo.amount) > promo.maxAmount) {
            errMsg = `刷卡金额最多可支付${promo.maxAmount}元`
            return false
          }
        }
      })
      return errMsg
    },
    submitPromo() {
      const errMsg = this.validForm()
      if (errMsg) {
        this.$toast(errMsg)
        return false
      }
      const selectedPromos = this.promoList.filter((x) => x.selected)
      addPropertyPromosApi(this.orderId, this.userId, this.empUserId, selectedPromos).then((res) => {
        if (res.status !== 0) {
          this.$toast('保存失败')
          return false
        }
        this.$emit('onSubmit', res.data)
        this.closeDialog()
      })
    },
    /**
     * 保留小数点后两位
     */
    amountInput(event, promo, index, name) {
      let val = event.match(/^\d*(\.?\d{0,2})/g)[0]
      this.$nextTick(() => {
        this.$set(promo, name, val)
      })
    },
    closeDialog(e) {
      this.$emit('onClose', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-img {
  width: 100%;
}
.form-dialog {
  /deep/ .wh-dialog-body {
    padding: 24px  0;
  }
  .collapse-box {
    width: 100%;
    max-height: 100vh;
    background-color: $color-background;
    transition: max-height 0.3s;
    overflow: hidden;
    &.hide {
      max-height: 0;
    }
  }
}

.user-box {
  display: flex;
  width: 100%;
  padding: 12px 28px;
  align-items: center;
  flex-wrap: wrap;
  .user-item {
    flex: 0 0 33.33%;
    padding: 12px;
    .user-item_btn {
      display: block;
      width: 100%;
      height: 60px;
      line-height: 56px;
      color: $color-text-sub;
      background-color: none;
      text-align: center;
      font-size: 24px ;
      border: 1px solid $color-text-sub;
      border-radius: 8px;
      &.active {
        color: $color-white;
        background-color: $color-primary;
        border: 1px solid $color-primary;
      }
    }
  }
}
.promo-warning {
  width: 100%;
  padding: 0 40px;
  margin-bottom: 24px ;
  line-height: 32px;
  text-align: left;
  font-size: 24px ;
  color: $color-warning;
}
.promo-box {
  width: 100%;
  .promo-select {
    font-size: 32px;
    color: $color-text-disabled;
    &.active {
      color: $color-primary;
    }
  }
  .iconduoxuan-bukexuan {
    opacity: 0.6;
  }
  .promo-form {
    padding: 16px 0;
    /deep/ .van-cell {
      padding: 8px 40px;
      background-color: $color-background;
      &::after {
        border: none;
      }
      .van-cell__title {
        padding: 10px 0;
      }
      .van-cell__value {
        padding: 10px 10px;
        background-color: $color-white;
      }
    }
    .cell-desc {
      padding: 0 40px 8px;
      background-color: $color-background;
      text-align: left;
      font-size: 24px ;
      color: $color-text-sub;
    }
  }
  .white-bg {
    padding: 8px 40px;
    font-size: 24px ;
    text-align: left;
    color: $color-text-sub;
    background-color: $color-white;
  }
  .promo-form-remark {
    width: 100%;
    padding: 0 40px;
    margin-top: 8px;
    text-align: left;
    font-size: 24px ;
    color: $color-warning;
  }
}

/deep/ .van-cell {
  padding: 24px  40px;
  .van-cell__title {
    text-align: left;
  }
  .van-cell__value {
    color: $color-text-sub;
  }
  .van-cell__right-icon {
    color: $color-text-sub;
  }
}
/deep/.zIndex {
  .wh-dialog-backdrop {
    z-index: 2100;
  }
  .wh-dialog-box {
    z-index: 2101;
  }
}
</style>
