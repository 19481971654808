<!--
 * @Author: hwu
 * @Date: 2021-02-06 20:58:30
 * @Description: 菜谱页面 -> 共同点餐 -> 支付成功引导通知
 * @LastEditTime: 2022-10-25 18:04:01
-->
<template>
  <div class="dialog-wrapper">
    <dialog-box title="温馨提示" :content="userName + '已支付下单'" :show="show" rightBtnText="关闭" @onRightClick="onClose"></dialog-box>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import DialogBox from '@/components/common/DialogBox'
import { getOrderForPrintBillApi } from '@/api/order'
import { printBillApi } from '@/api/apk'
export default {
  name: 'payment-success-dialog',
  components: { DialogBox },
  props: {
    show: { type: Boolean, required: true, default: false },
    userName: { type: String, required: true, default: '' },
    orderId: { type: String, required: false, default: '' }
  },
  computed: {
    ...mapState('order', ['entranceType', 'storeId', 'userId', 'isPad'])
  },
  watch: {
    show(val) {
      if (val) {
        this.printBill()
      }
    }
  },
  methods: {
    printBill() {
      if (!this.orderId) {
        return false
      }
      getOrderForPrintBillApi(this.orderId).then((res) => {
        if (res.status !== 0) {
          return false
        }
        const orderList = res.data
        printBillApi(JSON.stringify(orderList))
      })
    },
    onClose() {
      this.$router.push({
        name: 'OrderGuide',
        query: {
          storeId: this.storeId,
          userId: this.userId,
          entranceType: this.entranceType,
          isPad: this.isPad ? 1 : 0
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.qrcode-box {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  .qrcode-image {
    width: 280px;
  }
  .qrcode-text {
    color: $color-text-normal;
    font-size: 28px;
    font-weight: bold;
  }
}
.warning-box {
  width: 100%;
  text-align: center;
  .warning-normal {
    color: $color-text-normal;
    font-size: 30px;
  }
  .warning-main {
    color: $color-danger;
    font-size: 36px;
    font-weight: bold;
  }
}
</style>
