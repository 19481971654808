<!--
 * @Author: hwu
 * @Date: 2021-03-18 15:13:21
 * @Description: 订单支付页 -> 活动、券代码块
 * @LastEditTime: 2024-07-31 09:28:57
-->
<template>
  <div class="order-promo">
    <!-- 加价购商品 -->
    <div class="product-list" v-if="showAddBuyItems">
      <div class="scroll-container">
        <div class="scroll-box">
          <div class="product-box" v-for="(item, index) in addBuyItems" :key="`${index}${item.count}`" @click="showItemDetail(item)">
            <img class="product-img" :src="transferItemImage(item.image)" />
            <div class="product-name">原价{{ item.price }}元·{{ item.itemName }}</div>
            <div class="product-bottom">
              <div class="left-text">加{{ item.promoPrice }}元购</div>
              <div class="btn-box">
                <template v-if="item.count > 0">
                  <!-- 减 -->
                  <i class="iconfont iconjianshao item-btn_icon minus-btn" @click.stop="subAddBuyItem(item)"></i>
                  <!-- item-qty需要有最小宽度宽度 -->
                  <div class="item-qty">{{ item.count }}</div>
                </template>
                <!-- 加 -->
                <!-- :class="{disabled:}" -->
                <i class="iconfont iconzengjia-tianchong item-btn_icon" :class="{ disabled: (item.singleItemCount === 1 && item.count > 0) || addBuyItemTotalCount >= item.totalItemCount }" @click.stop="addBuyItem(item)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 到店活动 -->
    <div class="order-promo_list">
      <div class="promo-row" v-for="(promo, index) in promos" :key="index" :class="{ disabled: promo.disable === 1 || promo.immobilization === 1 }">
        <div class="promo-row_line">
          <div class="promo-row_left">
            <div class="promo-row_tag">
              <span class="tag-text">{{ promo.tag }}</span>
            </div>
            <div class="promo-row_info">
              <div class="promo-title">
                <span class="promo-title_text">{{ promo.promoName }}</span>
                <!-- 加价购活动不展示问号 -->
                <i v-if="promo.promoType !== 6" class="promo-title_icon iconfont iconshuoming" @click="showPromoExplain(promo)"></i>
              </div>
            </div>
          </div>
          <div class="promo-row_right" @click="refreshPromo(promo)">
            <div class="promo-row_settle">
              <div class="promo-amount" v-show="promo.selected === 1">-￥{{ promo.discountAmount }}</div>
              <div class="promo-select">
                <i class="iconfont iconduoxuan-xuanzhong active" v-if="promo.selected"></i>
                <!-- 后买单时，immobilization值为1时，禁用掉 -->
                <i class="iconfont iconduoxuan-bukexuan disabled" v-else-if="promo.disable === 1"></i>
                <i class="iconfont iconduoxuan-weixuanzhong" v-else></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索券码 -->
    <div class="order-promo_search">
      <div class="search-box">
        <div class="search-input">
          <form action="" @submit.prevent="$closeKeyboard()">
            <input type="tel" class="search-input_inner iconfont" v-model="couponQueryKey" :placeholder="couponQueryPlaceholder" />
          </form>
        </div>
        <div class="search-btn" @click="queryCoupon">
          <i class="iconfont iconyanquan"></i>
          <span>验券</span>
        </div>
      </div>
      <div class="search-desc">
        <span>同码多张券时，第一个券码可扫码验证，其他券码需输码验证！</span>
      </div>
    </div>
    <!-- 券码\物业活动\会员卡 -->
    <div class="order-promo_list has-padding-bottom">
      <div class="promo-row" v-for="(promo, index) in coupons" :key="index" :class="{ disabled: promo.disable === 1 || promo.immobilization === 1 }">
        <div class="promo-row_line">
          <div class="promo-row_left">
            <div class="promo-row_user">
              <img class="user-image" :src="transferUserImage(promo.userImage)" />
            </div>
            <div class="promo-row_info with-user">
              <div class="promo-title">
                <span class="promo-title_text">{{ promo.promoName }}</span>
                <i class="promo-title_icon iconfont iconshuoming" @click="showPromoExplain(promo)"></i>
              </div>
            </div>
          </div>
          <div class="promo-row_right">
            <div class="promo-row_settle" v-show="promo.needFixed === 0">
              <div class="touch-box" @click="refreshPromo(promo)"></div>
              <div class="promo-amount" v-show="promo.selected === 1">-￥{{ promo.discountAmount }}</div>
              <div class="promo-select">
                <i class="iconfont iconduoxuan-xuanzhong active" v-if="promo.selected"></i>
                <!-- 后买单时，immobilization值为1时，禁用掉 -->
                <i class="iconfont iconduoxuan-bukexuan disabled" v-else-if="promo.disable === 1"></i>
                <i class="iconfont iconduoxuan-weixuanzhong" v-else></i>
              </div>
            </div>
            <div class="promo-row_settle" v-show="promo.needFixed === 1">
              <div class="promo-amount" v-show="promo.selected === 1">-￥{{ promo.discountAmount }}</div>
              <div class="item-btn-box">
                <span class="item-btn minus-btn" v-show="promo.selectedNum > 0" @click.stop="minusCoupon(promo)">
                  <i class="iconfont iconjianshao"></i>
                </span>
                <div class="item-qty" v-show="promo.selected === 1">{{ promo.selectedNum }}</div>
                <span class="item-btn add-btn" :class="{ disabled: addCouponBtnDisabled(promo) }" @click.stop="addCoupon(promo)">
                  <i class="iconfont iconzengjia-tianchong"></i>
                </span>
              </div>
            </div>
            <div class="promo-row_ex" v-show="promo.selected === 1 && promo.exAmount > 0">
              <span>(溢收￥{{ promo.exAmount }})</span>
              <span class="warning-tips" v-if="promo.selected === 1 && promo.exAmount > 0">溢收部分不支持退款</span>
            </div>
          </div>
        </div>
        <div class="promo-desc" v-if="hasDesc(promo)">
          <template v-if="isAfterPayPage">
            <span v-if="promo.promoType === 25 || promo.promoType === 27">* 请点击【继续点餐】-【选好了】再勾选本券</span>
          </template>
          <template v-else>
            <span v-if="promo.tag === '券'">不退券、不兑换、不找零</span>
          </template>
          <span v-if="promo.promoType === 13">可用余额：￥{{ promo.balance }}</span>
          <span v-if="promo.promoType === 42">剩余积分：{{ promo.integral }}分</span>
        </div>
      </div>
    </div>
    <!-- 券包里不可用的券 -->
    <div class="other-coupon-row" v-if="unavailableCouponNum > 0" @click="unavailableCouponPopupShow = true">
      <div class="other-coupon-row_left">其他不满足使用条件的优惠券</div>
      <div class="other-coupon-row_right"></div>
    </div>

    <!-- 物业活动弹窗 -->
    <payment-promo-property-dialog :show="promoPropertyDialogShow" :orderId="this.orderId" :isAfterPayPage="isAfterPayPage" @onClose="promoPropertyDialogShow = false" @onSubmit="submitPropertyPromo" />
    <!-- 产品券 -->
    <payment-promo-item-dialog :show="couponItemDialogShow" :couponData="couponItemData" @onClose="couponItemDialogShow = false" @onSubmit="submitCouponItem" />
    <!-- 活动说明弹窗 -->
    <payment-promo-explain-dialog :show="promoExplainDialogShow" :promo="selectedExplainPromo" :brandCode="order.brandCode" @onClose="promoExplainDialogShow = false" />
    <!-- 不可用的卡券 -->
    <payment-promo-coupon-popup :show="unavailableCouponPopupShow" :coupons="unavailableCoupons" @onClose="unavailableCouponPopupShow = false" />
    <!-- 验券失败提示 -->
    <dialog-box title="验券提示" :show="couponQueryErrorDialogShow" showLeftBtn leftBtnText="重新输入券码" rightBtnText="服务员验券" @onLeftClick="closeCouponQueryError" @onRightClick="confirmCouponQueryError">
      <div slot="body" class="wh-dialog-body">
        <div class="wh-dialog-text">券码：{{ couponQueryKey }}，是否正确</div>
        <div class="wh-dialog-text">如券码无误，请联系服务员协助验券</div>
      </div>
    </dialog-box>
    <!-- 商品详情弹窗 -->
    <payment-promo-item-detail-popup :show="itemDetailPopupShow" :itemId="selectedItemId" :productionModelId="selectedProductionModelId" :itemPrice="selectedItemPrice" @onClose="itemDetailPopupShow = false" @onSubmit="submitItemDetail" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import DialogBox from '@/components/common/DialogBox'
import PaymentPromoPropertyDialog from './PaymentPromoPropertyDialog'
import PaymentPromoItemDialog from './PaymentPromoItemDialog.vue'
import PaymentPromoExplainDialog from './PaymentPromoExplainDialog'
import PaymentPromoCouponPopup from './PaymentPromoCouponPopup.vue'
import PaymentPromoItemDetailPopup from './PaymentPromoItemDetailPopup'
import { refreshPromoApi, queryCouponApi, addCouponItemsApi, addBuyItemsApi, subAddBuyItemsApi } from '@/api/order'
import { scanQrCodeApi, createScanDeviceConnectionApi } from '@/api/apk'
export default {
  name: 'payment-promo-box',
  components: {
    DialogBox,
    PaymentPromoPropertyDialog,
    PaymentPromoExplainDialog,
    PaymentPromoItemDialog,
    PaymentPromoCouponPopup,
    PaymentPromoItemDetailPopup
  },
  props: {
    order: { type: Object, required: true, default: () => {} },
    isPaying: { type: Boolean, required: true, default: false },
    isAfterPayPage: { type: Boolean, required: false, default: false } // 是否是后买单界面
  },
  data() {
    return {
      orderId: '',
      addBuyItems: [],
      promos: [],
      coupons: [],
      unavailableCoupons: [],
      couponQueryKey: '',
      couponQueryPlaceholder: '输"券码"或点击"验券"扫码',
      couponQueryErrorDialogShow: false,
      promoPropertyDialogShow: false,
      promoExplainDialogShow: false,
      selectedExplainPromo: {},
      couponItemDialogShow: false,
      couponItemData: {},
      unavailableCouponPopupShow: false,
      submitDisabled: false,
      itemDetailPopupShow: false,
      selectedItemId: '', // 选中的itemId
      selectedProductionModelId: '',
      selectedItemPrice: 0
    }
  },
  computed: {
    ...mapState('order', ['userId']),
    // 是否展示加价购商品
    showAddBuyItems() {
      if (this.addBuyItems && this.addBuyItems.length) {
        // 后买单
        if (this.isAfterPayPage) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    addCouponBtnDisabled() {
      return (promo) => {
        // disable(禁用)， immobilization（活动固定，已核销未下单、后买单模式有用）
        if (promo.disable === 1 || promo.immobilization === 1) {
          return true
        }
        if (promo.selectedNum >= promo.totalNum) {
          return true
        }
        const totalSelectedNum = this.order.promoInfos
          .filter((x) => x.promoId === promo.promoId)
          .reduce((result, item) => {
            return (result += item.selectedNum || 0)
          }, 0)
        if (promo.perDayTimes && totalSelectedNum >= promo.perDayTimes) {
          return true
        }
        return false
      }
    },
    addBuyItemTotalCount() {
      let totalCount = 0
      if (this.addBuyItems && this.addBuyItems.length) {
        this.addBuyItems.forEach((item) => {
          totalCount += Number(item.count) || 0
        })
        return totalCount
      } else {
        return totalCount
      }
    },
    unavailableCouponNum() {
      if (!this.unavailableCoupons || this.unavailableCoupons.length === 0) {
        return 0
      }
      let num = 0
      this.unavailableCoupons.forEach((item) => {
        if (!item.totalNum) {
          num += 1
          return true
        }
        num += item.totalNum
      })
      return num
    }
  },
  watch: {
    order(val) {
      this.handleOrderInfo()
    }
  },
  methods: {
    handleOrderInfo() {
      // 处理活动信息
      this.orderId = this.order.orderId
      this.addBuyItems = this.order.addBuyItems
      this.unavailableCoupons = this.order.unAvailablePromoInfos
      if (!this.order.promoInfos || this.order.promoInfos.length === 0) {
        this.promos = []
        this.coupons = []
        return false
      }
      this.promos = this.order.promoInfos.filter((x) => x.promoType < 10)
      this.coupons = this.order.promoInfos.filter((x) => x.promoType >= 10)
      this.$forceUpdate()
    },
    queryCoupon() {
      if (this.isPaying) {
        return false
      }
      if (this.submitDisabled) {
        return false
      }
      this.couponQueryKey = this.couponQueryKey.replace(/\s+/g, '')
      if (!this.couponQueryKey) {
        this.scanCouponQrCode()
        return false
      }
      if (this.couponQueryKey === '666') {
        this.promoPropertyDialogShow = true
        this.couponQueryKey = ''
        return false
      }
      if (this.couponQueryKey.length < 6) {
        this.$toast('请输入正确的券码')
        return false
      }
      this.submitDisabled = true
      queryCouponApi(this.couponQueryKey, this.orderId, this.userId).then((res) => {
        this.submitDisabled = false
        if (res.status !== 0) {
          this.$toast(res.msg)
          setTimeout(() => {
            this.couponQueryErrorDialogShow = true
          }, 1000)
          return false
        }
        this.$toast('验券成功')
        this.updateOrderInfo(res.data.order)
        this.couponQueryKey = ''
      })
    },
    scanCouponQrCode() {
      this.createScanCouponConnection()
      scanQrCodeApi()
    },
    // 扫码回调
    scanCouponCallBack(event) {
      if (event.data.state !== 'scanCallBack') {
        return false
      }
      this.setCouponKey(event.data.code)
    },
    setCouponKey(val) {
      this.closeScanConnection()

      if (!val) {
        this.$toast('请扫有效的券码')
        return false
      }
      const key = val.replace(/\s+/g, '')
      // 券包为6~20位的纯数字
      const regex = /^\d{6,20}$/
      //  key.indexOf("douyin")判断的是抖音的扫码验券，扫出来是一个短链接
      if (!regex.test(key) && key.indexOf('douyin') === -1) {
        this.$toast('请扫有效的券码')
        return false
      }
      this.couponQueryKey = key
      this.queryCoupon()
    },
    // 创建扫码枪链接，并绑定扫码回调时间
    createScanCouponConnection() {
      // 开启扫码枪连接
      createScanDeviceConnectionApi('4', '')

      if (this.$bappSdk.isBApp()) {
        window.scanCallBack = this.setCouponKey
      }
      if (this.isAfterPayPage) {
        // pos买单，是直接location跳转的。所以扫码回调的监听，需要采用这种形式
        window.scanCallBack = this.setCouponKey
      } else {
        // pos机点餐，iframe模式监听扫码回调通知
        window.addEventListener('message', this.scanCouponCallBack, false)
      }
    },
    closeScanConnection() {
      // 关闭扫码枪连接
      createScanDeviceConnectionApi('0', '')
      // 关闭扫码回调通知
      window.scanCallBack = null

      window.removeEventListener('message', this.scanCouponCallBack, false)
    },
    closeCouponQueryError() {
      this.couponQueryKey = ''
      this.couponQueryErrorDialogShow = false
    },
    confirmCouponQueryError() {
      this.couponQueryKey = '666'
      this.couponQueryErrorDialogShow = false
      this.queryCoupon()
    },
    submitPropertyPromo(detail) {
      this.updateOrderInfo(detail)
    },
    /**
     * 添加加价购商品
     */
    // totalItemCount：每单最多可加购几份商品
    // singleItemCount：每款商品可加购几份。0：多份。1:一份
    addBuyItem(item) {
      if ((item.singleItemCount === 1 && item.count > 0) || this.addBuyItemTotalCount >= item.totalItemCount) {
        return false
      }
      // fixed !== 0说明不需要拉起详情弹框，直接添加即可
      if (item.fixed !== 0) {
        const params = {
          itemId: item.itemId,
          productionModelId: item.productionModelId,
          count: 1
        }
        this.confirmAddBuyItem(params)
      } else {
        this.selectedItemId = item.itemId
        this.selectedProductionModelId = item.productionModelId
        this.selectedItemPrice = item.promoPrice
        this.itemDetailPopupShow = true
      }
    },
    showItemDetail(item) {
      this.selectedItemId = item.itemId
      this.selectedProductionModelId = item.productionModelId
      this.selectedItemPrice = item.promoPrice
      this.itemDetailPopupShow = true
    },
    submitItemDetail(itemForm) {
      this.itemDetailPopupShow = false
      this.$nextTick(() => {
        this.confirmAddBuyItem(itemForm)
      })
    },
    confirmAddBuyItem(params) {
      addBuyItemsApi(this.orderId, this.userId, params).then((res) => {
        if (res.status !== 0) {
          return false
        }
        if (res.data) {
          this.updateOrderInfo(res.data)
        }
      })
    },
    /**
     * 删减加价购商品
     */
    subAddBuyItem(item) {
      subAddBuyItemsApi(this.orderId, this.userId, item.itemId, item.productionModelId).then((res) => {
        if (res.status !== 0) {
          return false
        }
        if (res.data) {
          this.updateOrderInfo(res.data)
        }
      })
    },
    minusCoupon(promo) {
      if (this.isPaying) {
        return false
      }
      if (promo.immobilization === 1) {
        return false
      }
      const param = {
        orderId: this.orderId,
        selected: 0,
        userId: this.userId,
        promoUserId: promo.userId,
        promoId: promo.promoId,
        couponId: promo.couponId,
        batchId: promo.batchId
      }
      refreshPromoApi(param).then((res) => {
        if (res.status !== 0) {
          return false
        }
        if (res.data.order) {
          this.updateOrderInfo(res.data.order)
        }
      })
    },
    addCoupon(promo) {
      if (this.isPaying) {
        return false
      }
      if (this.addCouponBtnDisabled(promo)) {
        return false
      }
      const param = {
        orderId: this.orderId,
        selected: 1,
        userId: this.userId,
        promoUserId: promo.userId,
        promoId: promo.promoId,
        couponId: promo.couponId,
        batchId: promo.batchId
      }
      refreshPromoApi(param).then((res) => {
        if (res.status !== 0) {
          return false
        }
        // 如果是重新勾选，需要处理商品券处理商品券逻辑，重新选择商品
        this.handleCouponItem(res.data, promo.promoId, promo.couponId, promo.userId)
        if (res.data.order) {
          this.updateOrderInfo(res.data.order)
        }
      })
    },
    refreshPromo(promo) {
      if (this.isPaying) {
        return false
      }
      if (promo.disable === 1 || promo.immobilization === 1) {
        return false
      }
      const selectedStatus = promo.selected === 1 ? 0 : 1
      const param = {
        orderId: this.orderId,
        selected: selectedStatus,
        userId: this.userId,
        promoUserId: promo.userId,
        promoId: promo.promoId,
        couponId: promo.couponId,
        batchId: promo.batchId
      }
      refreshPromoApi(param).then((res) => {
        if (res.status !== 0) {
          return false
        }
        // 如果是重新勾选，需要处理商品券处理商品券逻辑，重新选择商品
        if (res.data && selectedStatus === 1) {
          this.handleCouponItem(res.data, promo.promoId, promo.couponId, promo.userId)
        }
        if (res.data && res.data.order) {
          this.updateOrderInfo(res.data.order)
        }
      })
    },
    handleCouponItem(data, promoId, couponId, promoUserId) {
      if (!data || !data.items || data.items.length === 0) {
        return false
      }
      if (!data.totalItemCount) {
        return false
      }
      const coupon = this.coupons.find((x) => x.promoId === promoId && x.userId === promoUserId)
      if (!coupon) {
        return false
      }

      // 如果只有一个商品，并且不是套餐或多规格商品，则直接加入购物车
      if (data.items.length === 1 && data.items[0].fixed !== 0) {
        this.addCouponItem(promoId, couponId, promoUserId, data.items[0], data.totalItemCount)
        return false
      }

      this.couponItemData = {
        orderId: this.orderId,
        promoId: promoId,
        couponId: couponId,
        couponName: coupon.promoName,
        items: data.items,
        totalCount: data.totalItemCount,
        singleCount: data.singleItemCount,
        userId: promoUserId
      }
      this.couponItemDialogShow = true
    },
    addCouponItem(promoId, couponId, promoUserId, item, qty) {
      const itemForm = {
        itemId: item.itemId,
        productionModelId: item.productionModelId,
        make: '',
        taste: '',
        quantity: qty
      }
      addCouponItemsApi(this.orderId, promoId, couponId, this.userId, promoUserId, [itemForm]).then((res) => {
        if (res.status !== 0) {
          this.$toast('添加失败')
          return false
        }
        // 刷新订单详情数据
        this.submitCouponItem(res.data)
      })
    },
    submitCouponItem(orderDetail) {
      this.updateOrderInfo(orderDetail)
      this.couponItemDialogShow = false
    },
    showPromoExplain(promo) {
      this.selectedExplainPromo = promo
      this.promoExplainDialogShow = true
    },
    updateOrderInfo(orderDetail) {
      this.$emit('onOrderUpdate', orderDetail)
    },
    hasDesc(promo) {
      if (promo.promoType === 13) {
        return true
      }
      if (promo.promoType === 42) {
        return true
      }
      if (this.isAfterPayPage) {
        if (promo.promoType === 20 || promo.promoType === 25 || promo.promoType === 27) {
          return true
        }
      } else {
        if (promo.tag === '券') {
          return true
        }
      }
      return false
    },
    transferItemImage(key) {
      if (!key) {
        return require('@/assets/images/item-default.png')
      }
      return process.env.VUE_APP_QNY_LOAD_URL + key + '?imageView2/0/w/400'
    },
    transferUserImage(url) {
      if (!url) {
        return require('@/assets/images/icon_head_error.png')
      }
      return url
    }
  }
}
</script>
<style lang="scss" scoped>
.order-promo {
  width: 100%;
  margin-top: 16px;
  .product-list {
    margin-bottom: 16px;
    .scroll-container {
      height: 328px;
      width: 100vw;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none !important;
      }

      .scroll-box {
        width: 100%;
        height: 100%;
        display: flex;
        padding-left: 24px;
      }
      .product-box {
        width: 290px;
        margin-left: 16px;
        padding: 16px 16px 20px 16px;
        border-radius: 16px;
        background-color: $color-white;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 24px;
        }
        .product-img {
          width: 258px;
          height: 210px;
          margin-bottom: 8px;
          border-radius: 8px;
        }
        .product-name {
          max-width: 258px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: 400;
          color: $color-text-main;
          line-height: 30px;
        }
        .product-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left-text {
            font-size: 24px;
            font-weight: bold;
            color: $color-primary;
            line-height: 30px;
          }
          .btn-box {
            // flex: 0;
            display: flex;
            align-items: center;
            .item-qty {
              // display: inline-block;
              min-width: 46px;
              text-align: center;
              font-size: 24px;
              font-weight: bold;
              color: $color-text-main;
              line-height: 28px;
            }
            .item-btn_icon {
              color: $color-primary;
              font-size: 40px;
              &.minus-btn {
                opacity: 0.8;
              }
              &.disabled {
                opacity: 0.5 !important;
              }
            }
          }
        }
      }
    }
  }
  .order-promo_list {
    width: 100%;
    background-color: $color-white;
    .promo-row {
      width: 100%;
      padding-left: 24px;
      padding-top: 24px;
      padding-bottom: 24px;

      &.disabled {
        opacity: 0.6;
      }
      .promo-row_line {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        .promo-row_left {
          flex: 1;
          position: relative;
          .promo-row_tag {
            position: absolute;
            top: 2px;
            left: 0;
            width: 36px;
            height: 36px;
            line-height: 36px;
            font-size: 0;
            text-align: center;
            color: $color-white;
            background-color: $color-danger;
            border-radius: 5px;
            transform: scale(0.9);
            transform-origin: center;
            .tag-text {
              font-size: 24px;
            }
          }
          .promo-row_user {
            // flex: 0 0 56px;
            // margin-right: 16px;
            // font-size: 0;
            position: absolute;
            top: -8px;
            left: 0;
            width: 56px;
            height: 56px;
            .user-image {
              width: 56px;
              height: 56px;
              border-radius: 50%;
            }
          }
          .promo-row_info {
            width: 100%;
            padding-left: 40px;
            line-height: 40px;
            &.with-user {
              padding-left: 64px;
            }
            .promo-title {
              width: 100%;
              .promo-title_text {
                font-size: 26px;
                color: $color-text-normal;
              }
              .promo-title_icon {
                display: inline-block;
                margin-left: 20px;
                color: $color-primary;
                font-size: 32px;
                vertical-align: middle;
              }
              .promo-title_btn {
                display: inline-block;
                margin-left: 20px;
                padding: 0 10px;
                line-height: 39px;
                font-size: 24px;
                color: $color-primary;
                border: 1px solid $color-primary;
                border-radius: 8px;
                white-space: nowrap;
                transform: scale(0.83);
                transform-origin: center;
                vertical-align: middle;
              }
            }
            .promo-desc {
              width: 100%;
              margin-top: 6px;
              color: $color-text-sub;
              font-size: 24px;
            }
          }
        }
        .promo-row_right {
          flex: 0;
          line-height: 40px;
          margin-left: 24px;
          padding: 24px;
          padding-bottom: 0;
          margin-top: -24px;
          .promo-row_settle {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            .promo-amount {
              flex: 0;
              margin-right: 12px;
              font-size: 26px;
              color: $color-text-normal;
              white-space: nowrap;
            }
            .promo-select {
              .iconfont {
                font-size: 32px;
                color: $color-text-sub;
                opacity: 0.5;
                &.active {
                  color: $color-primary;
                  opacity: 1;
                }
                &.disabled {
                  color: $color-text-disabled !important;
                  opacity: 0.5 !important;
                }
              }
            }
          }
          .promo-row_ex {
            position: relative;
            width: 100%;
            margin-top: 6px;
            color: $color-text-sub;
            font-size: 24px;
            white-space: nowrap;
          }
        }
      }
      .promo-desc {
        width: 100%;
        padding-left: 64px;
        color: $color-text-sub;
        font-size: 24px;
        margin-bottom: -24px;
      }
    }
  }
  .touch-box {
    position: absolute;
    top: -24px;
    right: -24px;
    bottom: -24px;
    left: -24px;
    z-index: 2;
  }
  .order-promo_search {
    width: 100%;
    padding: 24px;
    margin-top: 8px;
    background-color: $color-white;
    .search-box {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      align-items: center;
      .search-input {
        flex: 1;
        position: relative;
        .search-input_inner {
          width: 100%;
          height: 72px;
          padding: 0 0 0 24px;
          background: unset;
          font-size: 24px;
          border: 1px solid $color-primary-bg;
          // border-right: none;
          border-radius: 4px 0 0 4px !important;
          -webkit-appearance: none;
          &:focus {
            border: 1px solid $color-primary;
            caret-color: $color-primary;
          }
        }
        .search-input_suffix {
          position: absolute;
          right: 0;
          top: 0;
          height: 72px;
          line-height: 72px;
          padding: 0 24px;
          color: $color-text-sub;
        }
      }
      .search-btn {
        flex: 0;
        height: 72px;
        line-height: 72px;
        padding: 0 30px;
        color: $color-white;
        background-color: $color-primary;
        font-size: 24px;
        white-space: nowrap;
        border-radius: 0 4px 4px 0;
        .iconfont {
          margin-right: 8px;
          font-size: 24px;
        }
      }
    }
    .search-desc {
      width: 100%;
      margin-top: 16px;
      line-height: 36px;
      font-size: 24px;
      color: $color-text-sub;
    }
  }
}
.item-btn-box {
  flex: 0;
  display: flex;
  align-items: center;
  .item-btn {
    width: 40px;
    height: 40px;
    &.disabled {
      opacity: 0.5;
    }
    i {
      color: $color-primary;
      font-size: 40px;
    }
  }
  .item-qty {
    display: inline-block;
    min-width: 50px;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    color: $color-text-main;
  }
}
.other-coupon-row {
  display: flex;
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  align-items: center;
  font-size: 28px;
  background-color: $color-background;
  opacity: 0.5;
  .other-coupon-row_left {
    text-align: right;
    flex: 1;
  }
  .other-coupon-row_right {
    flex: 0;
    position: relative;
    display: flex;
    padding-right: 32px;
    line-height: 34px;
    align-items: center;
    white-space: nowrap;
    span {
      font-size: 28px;
      color: $color-primary;
    }
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      top: 50%;
      right: 8px;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      border-width: 1px 1px 0 0;
      border-color: $color-text-normal;
      border-style: solid;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.warning-tips {
  position: absolute;
  top: 100%;
  right: 50%;
  width: 240px;
  padding: 6px 10px;
  margin-right: -120px;
  font-size: 24px;
  text-align: center;
  color: $color-white;
  background-color: $color-warning;
  opacity: 0.8;
  border-radius: 8px;
  z-index: 11;
  transform: scale(0.8);
  transform-origin: center left;
  white-space: normal;
  &.no-warp {
    width: 400px;
    margin-right: -200px;
    white-space: nowrap;
  }
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 50%;
    width: 0;
    height: 0;
    margin-top: -7px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid rgba($color-warning, 0.9);
    transform: rotate(180deg);
    z-index: 11;
  }
}
.wh-dialog-body {
  width: 100%;
  text-align: left;
  .wh-dialog-text {
    margin-bottom: 16px;
    line-height: 32px;
    font-size: 28px;
    color: $color-text-main;
  }
  .wh-dialog-desc {
    line-height: 32px;
    font-size: 24px;
    color: $color-text-sub;
  }
  .wh-dialog-warning {
    margin-right: 8px;
    color: $color-warning;
  }
}
.has-padding-bottom {
  padding-bottom: 24px;
}
</style>
